import styled from "styled-components";

export const SubscribeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  background: #000;
  width: 100%;
  margin-bottom: 16px;
  @media screen and (max-width: 425px) {
    height: 100%;
    max-height: 100%;
    width: 100%;
  }
`;
export const ImageWrpper = styled.div`
  display: flex;
  flex-direction: column;
  width: 55%;
  padding-bottom: 16px;
  border-bottom: 1px solid #ffffff94;
`;
export const Image = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 100%;
`;

export const SloganWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 16px 0px 47px 0px;
  border-bottom: 2px solid #fff;
`;
export const Slogan = styled.h1`
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
  color: #fff;
  text-align: center;
  @media screen and (max-width: 425px) {
    font-size: 21px;
    font-weight: 500;
    line-height: 32px;
  }
`;
