import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 12px;
  left: 12px;

  @media (max-width: 1439px) {
    width: calc(100% - 12px);
    flex-direction: row;
    justify-content: space-between;
  }

  @media screen and (max-width: 425px) {
    bottom: 20px;
    left: 20px;
    width: calc(100% - 40px);
    gap: 20px;
  }
`;

export const CardTime = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 40px;
  margin-bottom: 12px;
  font-size: 12px;
  font-weight: 500;
  color: white;
  background: black;
  border-radius: 12px;

  @media (max-width: 1439px) {
    margin-bottom: 0;
  }

  @media screen and (max-width: 425px) {
    flex: 1;
    justify-content: center;
    align-items: center;
  }
`;

export const CardShare = styled(CardTime)`
  color: black;
  background: white;
  border: 2px solid black;
  margin-bottom: 0px;
  position: relative;
`;
