export type MyOptionType = {
  label: string;
  value: string;
};
export const options: MyOptionType[] = [
  { value: "turkey", label: "Türkiye" },
  { value: "singapure", label: "Singapur" },
  { value: "usa", label: "USA" },
  { value: "russia", label: "Rusya" },
  { value: "china", label: "Çin" },
  { value: "france", label: "Fransa" },
];

export const ExpiresMonth: MyOptionType[] = [
  { value: "01", label: "01" },
  { value: "02", label: "02" },
  { value: "03", label: "03" },
  { value: "04", label: "04" },
  { value: "05", label: "05" },
  { value: "06", label: "06" },
  { value: "07", label: "07" },
  { value: "08", label: "08" },
  { value: "09", label: "09" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
];

const currentYear = new Date().getFullYear();
export const ExpiresYear: MyOptionType[] = [];
for (let index = 0; index < 20; index++) {
  ExpiresYear.push({
    value: (currentYear + index).toString(),
    label: (currentYear + index).toString(),
  });
}
