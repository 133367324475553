import { ContentStyle } from './style';

const Turkish = () => {
  return (
    <ContentStyle>
        <p>&nbsp;</p>
        <p>With this agreement, the data owners and users give explicit consent to how their data is going to be processed on “www.<strong><em>SVAYP</em></strong>.com”, on it’s applications, mobile versions and similar systems as it is explained in the privacy policy of the <strong><em>SVAYP</em></strong> whose representative is TARKAN KAYA <span>(tarkan.kaya@gmail.com / tarkan@<strong><em>SVAYP</em></strong>.com)</span>. The EXPLICIT CONSENT which is stated in the law 6698 on the Protection of Private Data (KVKK) is thus given by this user agreement.</p>
        <p>Terms of use and commercial conditions of <strong><em>SVAYP</em></strong> are also regulated in this aggreement and will be enforced upon the form of a mutual and proper consent.</p>
        <p>This agreement is an addition to the privacy policy.<span>&nbsp; </span>In so far, personal data begins to be processed as specified in the privacy policy after duly enforcement; The agreed commercial terms become binding. About the matters that are not mentioned separately in the aggreement, mandatory and regulatory provisions of current legislation is applied.</p>
        <p>&nbsp;</p>
        <p style={{textAlign: 'center'}}><span><strong>T E R M S</strong></span></p>
        <p><strong><em>Parties</em></strong></p>
        <p><strong>ARTICLE 1.-</strong></p>
        <ul>
          <li>This aggreement is concluded between <strong><em>SVAYP</em></strong>, which has the title of "proposer" and "data controller", and <strong>the "DATA OWNER (USER)"</strong> with the title of "acceptor", and has its provisions and results within the framework of honesty rules.&nbsp;</li>
        </ul>
        <ul>
          <li>The aggreement becomes valid with the existence of proper mutual consent. The procedure explains that the user accepts the aggreement <strong>electronically</strong> or <strong>physically</strong> and thus the explicit consent is fulfilled. The consent in the electronic environment is defined as checking the approval button provided by <strong><em>SVAYP</em></strong>.</li>
        </ul>
        <ul>
          <li>As the law 6698 article 3/1 ç states, since legal entities are not considered to be data owners, the explicit consent to be given with this aggreement (for data processing) will only have consequences for natural persons. Legal person users are bound by only the commercial provisions of the aggreement.</li>
        </ul>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p><strong><em>The Content, It’s Scope and Legal Nature of the Agreement</em></strong></p>
        <p><strong>ARTICLE 2.-</strong></p>
        <ul>
          <li>The agreement includes basics about the parties’ rights and responsibilities and ensures to follow Turkish Law of Obligations numbered 6098 ; KVKK (The Law on the Protection of Private Data) 6698 and includes basics on what does the data owners <span><strong>explicitly consent for,</strong></span> thus fulfills it’s <strong>responsibility of clarification</strong>.</li>
        </ul>
        <ul>
          <li>The agreement makes both parties liable to the topics with it’s essense and statements. In addition to this, for the matters that are not settled in this agreement, 6698th Law on Protection of Private Data, GDPR and the law 6098 Turkish Law of Obligations’ related provisions and similar legal legistations’ commanding and regulating terms apply. GDPR is applies to the extend where it complies with KVKK (6698).</li>
        </ul>
        <p>&nbsp;</p>
        <p><span><strong><em>Rights and Responsibilities of the Parties</em></strong></span></p>
        <p>&nbsp;</p>
        <p><strong>ARTICLE 3.- </strong></p>
        <p>&nbsp;</p>
        <ul>
          <li>The data holder accepts, states and guerentees that he/she will use the given data in a lawful way which is stated in this user aggrement.</li>
        </ul>
        <ul>
          <li>The data can be processed based on the explicit consent of it’s owner. Explicit consent is given related to the processing ways stated in the privacy policy.</li>
        </ul>
        <ul>
          <li>The data, as stated in the privacy policy, is processed as follows:</li>
        </ul>
        <ul>
          <li><strong><em>SVAYP</em></strong> processes said private data in accordance with it’s commercial purposes in a proportionate way. To enter and register to <strong><em>SVAYP</em></strong>, it’s necessary to register with a user name and a password which will be automatically saved to the software. In the registration process, <strong>personal data such as phone number may be demanded</strong> which is then saved to the safe sofware that belongs to <strong><em>SVAYP</em></strong>. It is possible that personal information may not be demanded though <strong><em>SVAYP</em></strong> has the initiative in this regard. <strong><em>SVAYP</em></strong> guerentees that it will follow the 6698th law on Protection of Personal Data meanwhile it reciprocates that it will process data in a proportionate way in accordance with the purposes stated in the Privacy Policy.</li>
        </ul>
        <ul>
          <li><strong><em>Svayp</em> </strong>processes said personal data to conduct a public opinion survey (poll) to get scientific and statistical info to share it with public and share the results with third parties. <strong><em>Svayp</em></strong> will gather the info and data compliyng with the legal conditions and use it , store it (for a while), share it with third parties, turn it into a poll by anonymizing and be able to process in every way possible mentioned above. In other words: In other words, <span><strong>personal opinions and preferences on <em>SVAYP</em>, after they are anonymized (by hiding names and surnames of the data owners) and thus kept hidden, are used as a poll. This data, then, is used to help commercial businesses and similar third parties helping them with their strategic planning. Said data after the anonymization process, is transferred to third parties or similar businesses.</strong></span></li>
        </ul>
        <ul>
          <li>Data owners, after the process of registration, may share their miscelleneous personal data. These data will be considered PUBLIC, thus according to 6698th law (on Personal Data) Article 5, may be processed without the need of an explicit consent.</li>
        </ul>
        <ul>
          <li>Before and after data owners register to <strong><em>SVAYP</em></strong> as “members”, <strong><em>Svayp</em></strong> processes browser infos and cookies which are provided via technical infos that are provided by the device they are using. These devices are then tagged by <strong><em>SVAYP</em></strong>. Users are provided with a warning text regarding this once they enter the website. <strong>Cookies that are mandatory</strong> for the website to operate are <strong>processed the moment user enters the website</strong>. For mandatory cookies, no explicit consent is required. The user is immediately notified that the website processes the mandatory cookies as the user enters the website. This notification is done via a warning in the main page. However, functional cookies and ad cookies can only be used only with the user’s explicit consent. Details regarding this are explained in the privacy policy which is an addition of this document.</li>
        </ul>
        <ul>
          <li>Advertisors and their advertisement managers are responsible for the ads shown to users of SVAYP which indicates <strong><em>SVAYP</em></strong> cannot be hold liable about the advertisemental content that it’s users encounters.</li>
        </ul>
        <ul>
          <li>In the cases mentioned bellow, <strong><em>SVAYP</em></strong> can process info <strong>without any need of an explicit consent</strong>:</li>
        </ul>
        <ul>
          <li>it is clearly provided for by the laws.</li>
        </ul>
        <ul>
          <li>it is mandatory for the protection of life or physical integrity of the</li>
        </ul>
        <ul>
          <li>person or of any other person who is bodily incapable of giving his consent or whose consent is not deemed legally valid.</li>
        </ul>
        <ul>
          <li>processing of personal data belonging to the parties of a aggreement, is necessary provided that it is directly related to the conclusion or fulfilment of that aggreement.</li>
        </ul>
        <ul>
          <li>it is mandatory for the controller to be able to perform his legal obligations.</li>
        </ul>
        <ul>
          <li>the data concerned is made available to the <span><strong><em>PUBLIC</em></strong></span> by the data subject himself.</li>
        </ul>
        <ul>
          <li>it is mandatory for the <span><strong>legitimate interests</strong></span> of the controller, provided that this processing shall not violate the fundamental rights and freedoms of the data subject.</li>
        </ul>
        <ul>
          <li><span><strong>When data processing is no longer required</strong></span>, data holder deletes or anonymizes data if the owner demands it or it is required by law.</li>
        </ul>
        <ul>
          <li><strong><em>SVAYP</em></strong> may make changes on it’s website and application freely. <strong><em>SVAYP</em></strong> may also change the way of work and processing, or change/remove the rules of (data) sharing. <strong><em>SVAYP</em></strong> does not have the obligation to inform it’s users in this regard.</li>
        </ul>
        <ul>
          <li>The data subject accepts, declares and undertakes that he/she will use the <strong><em>SVAYP</em></strong> for his legal business and transactions, and will comply with this aggreement, current legislation and other foreseen terms and conditions. <strong><em>SVAYP</em></strong> administration or organ representatives are not responsible for the crimes that are committed via <strong><em>SVAYP</em></strong> thus no repercussion can be demanded from <strong><em>SVAYP</em></strong>.</li>
        </ul>
        <ul>
          <li>Everyone, in connection with herself/himself, has the right to;</li>
        </ul>
        <p>&nbsp;</p>
        <ul>
          <li>Learn whether or not her/his personal data have been processed;</li>
          <li>Request information about processing if her/his data have been processed;</li>
          <li>Learn the purpose of processing of the personal data and whether data&nbsp;are used in accordance with their purpose;</li>
          <li>Learn the third parties to whom their personal data have been transferred;</li>
          <li>Request rectification if the personal data is processed incompletely or inaccurately;</li>
          <li><strong>Request deletion or anhilation of personal data within the framework of the conditions; (The request will have consequences that will affect the future while the acts before the request will remain legally valid)</strong></li>
          <li>Request SVAYP to notify the third parties whom the personal data of the user is transferred when the user requests their data to be deleted and removed</li>
          <li>Object to occurrence of any result that is to her/his&nbsp;detriment by means of analysis of personal data exclusively through automated systems;</li>
          <li>Request compensation for the damages in case the person incurs damages due to unlawful processing of personal data</li>
        </ul>
        <p>by applying to the data controller.</p>
        <p>&nbsp;</p>
        <ul>
          <li><span><strong>Data owner accepts and states that he/she gives explicit consent to the said processing ways and the ways that are explained in the privacy policy.</strong></span> In addition, he/she acknowledges and declares that his/her explicit consent is BASED ON THE BEING INFORMED about the user agreement.<span>&nbsp; </span>accepts and states that his/her will is EXPLICIT on the matters he/she has given is certain, and that his/her consent is NOT DAMAGED in case of mischief of will.</li>
        </ul>
        <ul>
          <li><strong><em>SVAYP</em></strong> has the right to suspend the user's membership or terminate all rights and capacities (authorizations)<span>&nbsp; </span>of the user by terminating the aggreement if the user violates the terms of this aggreement and the other terms of the software and the statements and commitments within this scope. In this case, damages occuring from violation can be demanded.</li>
        </ul>
        <p>&nbsp;</p>
        <p><span><strong><em>Intellectual and Industrial Property Rights</em></strong></span></p>
        <p>&nbsp;</p>
        <p><strong>ARTICLE 4.-</strong></p>
        <p>&nbsp;</p>
        <ul>
          <li><strong><em>SVAYP’s</em></strong> every element such as interface, software, services, intellectual property, designs and other elements that are intellectually and technically a property belongs to it’s owner. Unauthorized copying, transfer or reproduction in a any way violates this agreement and intellectual property rights.</li>
        </ul>
        <ul>
          <li>In violation of this article, priority being on the 5846th Law on Intellectual and Artistic Works; 6098 Turkish Law of Obligation’s repercussion terms and 5232 Turkish Penal Law punital terms are executed.</li>
        </ul>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p><span><strong><em>Various Responsibilities</em></strong></span></p>
        <p><strong>ARTICLE 5.-</strong></p>
        <p>&nbsp;</p>
        <ul>
          <li>All content on <strong><em>SVAYP’s</em></strong> website and applications are published “<em>as they are</em>” thus <strong><em>SVAYP</em></strong> has no responsibility on the integrity and reliability of the content.</li>
        </ul>
        <p>The user is responsible for all kinds of act, all kinds of content they upload and all unlawful conducts he/she does whilst using <strong><em>SVAYP</em></strong> personally and exclusively.</p>
        <p><span><br /> </span><span><strong><em>Enforcement of the Aggreement and Termination</em></strong></span></p>
        <ul>
          <ul>
            <li><strong><em>Enforcement</em></strong></li>
          </ul>
        </ul>
        <p><strong>ARTICLE 6.-</strong> This agreement becomes effective with the consent of the parties via any channel and continues to be enforced until it is terminated. If the consent is given via an electronic channel, consent is considered given when the user uses the “agree” button meanwhile <strong><em>SVAYP</em></strong>’s consent is considered automatically given as it provides this agreement to it’s users.</p>
        <ul>
          <ul>
            <li><strong><em>Termination</em></strong></li>
          </ul>
        </ul>
        <p><strong>ARTICLE 7.- </strong></p>
        <ul>
          <li>Any of the parties may terminate this agreement provided that they inform the other party one day prior in a written announcement. Termination becomes effective when the announcement arrives at the other party. Termination may be carried out without any restrictions to it’s form. When a serious grounds for termination arises, an immediate termination can be carried out without notifying the other party.</li>
        </ul>
        <ul>
          <li>The termination of the agreement do not remove the rights and responsibilities existed before the act of termination.</li>
        </ul>
        <ul>
          <li>A notification of consent means data owner’s withdrawal of consent on further processing <span><strong>though it should be noted that termination has a further effective consequences meaning the former acts of data processing remains valid.</strong></span></li>
        </ul>
        <ul>
          <li>The user may demand the deletion of his/her data according to this aggreement amd 6698th law on Protection of Private Data <span><strong>with the condition of the termination of the reasons of processing and the data being unactionable due to the framework of rules of honesty</strong></span>. But it is also possible that the user may demand wrongfully or ill processed data to be deleted or change while he / she may also demand to get informed about the data and how it is processed. Contact: <span className="s4">tarkan@svayp.com</span><span className="s5">.</span></li>
        </ul>
        <p>&nbsp;</p>
        <p><span><strong><em>Other Provisions</em></strong></span></p>
        <p>&nbsp;</p>
        <p><strong>ARTICLE 8.-</strong> If <strong><em>SVAYP</em></strong> suffers financial loss occuring from the violation of the conditions of this aggreement, <strong><em>SVAYP</em></strong> can demand the damages reimbursed by pleading 6098th Turkish Law of Obligations provisions. The compensation is paid by the user or the person who caused the damage, and the legal interest is applied to the compensation amount in accordance with the Law No. 3095 (for December 2020: 9%) to process from the date of tort. Subsequently, the aggreementual interest can be determined. Rights and obligations arising from exclusive provisions to be applied in terms of commercial affairs and rights arising from the default interest and the right to compensation for excessive damage are reserved.</p>
        <p>&nbsp;</p>
        <p><strong>ARTICLE 9.-</strong> In the event that any of the provisions of this aggreement is against the mandatory legal norms in force, the agreement continues its validity with other provisions other than the contrary provision.</p>
        <p>&nbsp;</p>
        <p><strong>ARTICLE 10.-</strong> For matters that are not covered by this agreement, the provisions of the Privacy Policy and the relevant legal legislation shall be taken as basis for the issues.</p>
        <p>&nbsp;</p>
        <p><strong>ARTICLE 11.-</strong> The courts and enforcement offices in the residence of the <strong><em>SVAYP representative</em></strong> are deemed to be authorized in the disputes arising from the <strong>Privacy Policy</strong> in the nature of this contract and its termination.</p>
        <p>&nbsp;</p>
      </ContentStyle>
  )
}

export default Turkish