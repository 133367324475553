export const countries = [
  { id: "f88c8e76-6e6a-4859-ac8c-078b4aef0a05", name: "TURKEY", code: "tr" },
  { id: "a3ea9513-89f4-4fe0-9ffa-89a9cdcdf084", name: "SINGAPORE", code: "sg" },
  { id: "5961be98-9457-4b0f-8724-1d9902244482", name: "USA", code: "us" },
  { id: "baba323d-b765-4c75-8895-d4785bc6807c", name: "NORWAY", code: "no" },
  { id: "6fad7130-fb45-4c36-87e6-6da24c677d3f", name: "RUSSIA", code: "ru" },
  { id: "1c2228d0-a881-4bb2-bf88-d7e1c7e3d6e6", name: "CHINA", code: "cn" },
  { id: "8fbf88d2-fefb-4170-a66b-33d2fe8d1e72", name: "AUSTRALIA", code: "au" },
  { id: "dd6ebdd2-7209-4999-ba4a-e1cb61cff95b", name: "UNITED KINGDOM", code: "gb" },
  { id: "d99b7c99-5b4f-48e3-936f-b976b8b8409f", name: "CZECHIA", code: "cz" },
  { id: "b2078873-6f22-4d57-b0ff-9749266e32f8", name: "FRANCE", code: "fr" },
  { id: "cfc7b768-1dbd-46c0-bb1e-caadeb10bccb", name: "GERMANY", code: "de" },
  { id: "6397116b-9dc8-441c-9683-1f5cd7fdaba9", name: "INDIA", code: "in" },
  { id: "", name: "NETHERLANDS" },
  { id: "", name: "ITALY" },
  { id: "", name: "GREECE" },
];

export const categories = [
  "Tümü",
  "Elon Musk",
  "Ahmet Hakan",
  "Türkiye",
  "MHP",
  "Dünya",
  "Genel",
  "Netflix",
  "Soru",
  "Sturbucks",
  "USA",
  "Fenerbahçe",
  "Tarım ve Hayvancılık",
];
