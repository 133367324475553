import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  width: 320px;
  height: 410px;
  padding: 32px;
  border-radius: 12px;
  @media screen and (min-width: 1024px) and (max-width: 1440px) {
    width: 280px;
  }
`;
export const CardsTitleWrapper = styled.div<{
  borderColor: any;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 2px solid ${(props) => `${props.borderColor}`};
`;

export const CardsDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 12px;
  padding-top: 12px;
  font-size: 12px;
  color: #5a5a5a;
  gap: 12px;
`;
