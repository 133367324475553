import React from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import Button from "../../../../components/button";
import { setSubscribePacket } from "../../../../redux/features/paymentSlice";
import { useAppDispatch } from "../../../../redux/store";
import { CardsDescriptionWrapper, CardsTitleWrapper, Wrapper } from "./styles";

type Card = {
  borderColor: string;
  title: string;
  price: string;
  list: string[];
};
interface ISubscribeCards {
  card: Card;
}
const SubscribeCards = ({ card }: ISubscribeCards) => {
  const { t } = useTranslation(['subscribe']);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { borderColor, title, price, list } = card;
  return (
    <Wrapper>
      <CardsTitleWrapper borderColor={borderColor}>
        <h1
          style={{
            display: "flex",
            fontSize: 18,
            fontWeight: "500",
            lineHeight: "22px",
            fontFamily: "Poppins",
          }}
        >
          {title}
        </h1>
      </CardsTitleWrapper>
      <p
        style={{
          display: "flex",
          width: "100%",
          paddingBottom: 24,
          paddingTop: 24,
          fontSize: 24,
        }}
      >
        {price}
      </p>
      <Button
        borderColor={borderColor}
        onClick={() => {
          const selectedSubscribe = {
            label: title,
            color: borderColor,
            price: price,
          };
          dispatch(setSubscribePacket(selectedSubscribe));
          navigate("/payment");
        }}
      >
        {t('continue')}
      </Button>
      <CardsDescriptionWrapper>
        {list?.map((item: string) => (
          <div
            key={item}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                marginTop: 8,
                border: `3px solid ${borderColor}`,
                marginRight: 8,
                borderRadius: 100,
              }}
            />
            <p style={{ marginTop: 4, textAlign: "left" }}>{item}</p>
          </div>
        ))}
      </CardsDescriptionWrapper>
      <div
        style={{
          position: "absolute",
          bottom: -4,
          width: "100%",
          paddingLeft: 32,
          paddingRight: 32,
        }}
      >
        <div
          style={{
            border: `6px solid ${borderColor}`,
          }}
        />
      </div>
    </Wrapper>
  );
};

export default SubscribeCards;
