import { FormikProps, useFormik } from "formik";
import Button from "../../../../components/button";
import LabelwithInput from "../../../../components/input";
import RadioButton from "../../../../components/radioButton";
import SelectBox from "../../../../components/selectBox";
import { Size, useWindowSize } from "../../../../hooks/useWindowSize";
import {
  ExpiresMonth,
  ExpiresYear,
  MyOptionType,
  options,
} from "../../../../mock/select-options-data";
import { setOrderCardInfo } from "../../../../redux/features/paymentSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { BottomLine, BottomLineWrapper } from "../AccountCard/style";
import {
  Container,
  Form,
  FormContainer,
  FormSubmitContainer,
  FormSubmitParagraph,
  FormTitle,
  Header,
  HeaderContainer,
} from "./styles";
export interface formValuesOrder {
  paymnetMethod: string;
  cardNumber: string;
  cardName: string;
  expiresMonth: string;
  expiresYears: string;
  cvv: string;
  address: string;
  zip: string;
  city: string;
  country: string;
  region: string;
}
const array = [
  { label: "Credit Card", value: "creditCard" },
  { label: "Stripe", value: "stripe" },
];
const OrderCard = ({
  setOpen,
  setOtherCard,
}: {
  setOpen: any;
  setOtherCard: any;
}) => {
  const dispatch = useAppDispatch();
  const { subscribePacket } = useAppSelector((state) => state.payment);
  const size: Size = useWindowSize();
  const width = size.width !== undefined && size.width >= 425 ? "48%" : "100%";
  const buttonWidth =
    size.width !== undefined && size.width >= 425 ? "30%" : "100%";
  const formik: FormikProps<formValuesOrder> = useFormik<formValuesOrder>({
    initialValues: {
      paymnetMethod: "creditCard",
      cardNumber: "",
      cardName: "",
      expiresMonth: "",
      expiresYears: "",
      cvv: "",
      address: "",
      zip: "",
      city: "",
      country: "",
      region: "",
    },
    onSubmit: (values) => console.log("Val", values),
    validate: (values) => {
      let errors: formValuesOrder = {
        paymnetMethod: "",
        cardNumber: "",
        cardName: "",
        expiresMonth: "",
        expiresYears: "",
        cvv: "",
        address: "",
        zip: "",
        city: "",
        country: "",
        region: "",
      };
      if (!values.paymnetMethod) {
        errors.paymnetMethod = "Required!";
      }
      if (!values.cardNumber) {
        errors.cardNumber = "Required!";
      }
      if (!values.cardName) {
        errors.cardName = "Required!";
      }
      if (!values.expiresMonth) {
        errors.expiresMonth = "Required!";
      }
      if (!values.expiresYears) {
        errors.expiresYears = "Required!";
      }
      if (!values.cvv) {
        errors.cvv = "Required!";
      }
      if (!values.country) {
        errors.country = "Required!";
      }
      return errors;
    },
  });
  return (
    <Container>
      <HeaderContainer borderColor={subscribePacket.color}>
        <Header>{subscribePacket.label}</Header>
      </HeaderContainer>
      <FormContainer>
        <FormTitle>Ödeme</FormTitle>
        <form onSubmit={formik.handleSubmit}>
          <Form>
            <RadioButton
              title="Select a payment method"
              array={array}
              selected={formik.values.paymnetMethod}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                console.log("val", e.target.value);
                formik.setFieldValue("expiresMonth", e.target.value);
                console.log("formik", formik.values);
              }}
            />
            <LabelwithInput
              value={formik.values.cardNumber}
              handleChange={formik.handleChange}
              maxLength={19}
              name="cardNumber"
              type="tel"
              placeholder="Kart Numarası"
              style={{
                width: width,
                marginBottom: 32,
                borderColor: "red",
              }}
              errors={formik.errors.cardNumber ? true : false}
            />
            <LabelwithInput
              value={formik.values.cardName}
              handleChange={formik.handleChange}
              name="cardName"
              placeholder="Kart Üzerindeki Ad Soyad"
              style={{
                width: width,
                marginBottom: 32,
              }}
              errors={formik.errors.cardName ? true : false}
            />
            <SelectBox
              placeholder="Expires on"
              value={formik.values.expiresMonth}
              handleChange={(value: MyOptionType) =>
                formik.setFieldValue("expiresMonth", value.value)
              }
              errors={formik.errors.expiresMonth ? true : false}
              options={ExpiresMonth}
              style={{
                width:
                  size.width !== undefined && size.width >= 425 ? "22%" : "48%",
                marginBottom: 32,
              }}
            />
            <SelectBox
              placeholder="Expires on"
              value={formik.values.expiresYears}
              handleChange={(value: MyOptionType) =>
                formik.setFieldValue("expiresYears", value.value)
              }
              errors={formik.errors.expiresYears ? true : false}
              options={ExpiresYear}
              style={{
                width:
                  size.width !== undefined && size.width >= 425 ? "22%" : "48%",
                marginBottom: 32,
              }}
            />
            <LabelwithInput
              value={formik.values.cvv}
              handleChange={formik.handleChange}
              type="number"
              name="cvv"
              placeholder="CVV/CVC"
              style={{
                width: width,
                marginBottom: 32,
              }}
              errors={formik.errors.cvv ? true : false}
            />
            <LabelwithInput
              value={formik.values.address}
              handleChange={formik.handleChange}
              name="address"
              placeholder="Adres"
              style={{
                width: width,
                marginBottom: 32,
              }}
              errors={formik.errors.address ? true : false}
            />

            <LabelwithInput
              value={formik.values.zip}
              handleChange={formik.handleChange}
              type="number"
              name="zip"
              placeholder="ZIP/Postal Code"
              style={{
                width: width,
                marginBottom: 32,
              }}
              errors={formik.errors.zip ? true : false}
            />
            <LabelwithInput
              value={formik.values.city}
              handleChange={formik.handleChange}
              name="city"
              placeholder="City"
              style={{
                width: width,
                marginBottom: 32,
              }}
              errors={formik.errors.city ? true : false}
            />
            <SelectBox
              placeholder="Ülke"
              value={formik.values.country}
              handleChange={(value: MyOptionType) =>
                formik.setFieldValue("country", value.value)
              }
              errors={formik.errors.country ? true : false}
              options={options}
              style={{
                width: width,
                marginBottom: 32,
              }}
            />
            <LabelwithInput
              value={formik.values.region}
              handleChange={formik.handleChange}
              name="region"
              placeholder="State/Region"
              style={{
                width: width,
                marginBottom: 32,
              }}
              errors={formik.errors.region ? true : false}
            />
          </Form>
          <FormSubmitContainer>
            <Button
              type="submit"
              bgColor="#000"
              style={{
                color: "white",
                maxWidth: buttonWidth,
              }}
              onClick={() => {
                dispatch(setOrderCardInfo(formik.values));

                setOpen(false);
              }}
            >
              Devam Et
            </Button>
            <FormSubmitParagraph>
              Devam’a tıklayarak Kullanım Koşullarını ve Gizlilik Politikasını
              kabul etmiş olursunuz. Ayrıca 16 yaşından büyük olduğunuzu kabul
              ediyorsunuz.
            </FormSubmitParagraph>
          </FormSubmitContainer>
        </form>
      </FormContainer>
      <BottomLineWrapper>
        <BottomLine color={subscribePacket.color} />
      </BottomLineWrapper>
    </Container>
  );
};

export default OrderCard;
