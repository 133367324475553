/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useKey } from "../../hooks/useKey";
import { useWidth } from "../../hooks/useWidth";
import RightArrow from "../../assets/home/rightArrow.svg";
import { setSkip } from "../../redux/features/cardsSlice";
import LeftArrow from "../../assets/home/leftArrow.svg";
import TurkeyIcon from "../../assets/home/flags/trFlag.svg";
import SingaporeIcon from "../../assets/home/flags/sgFlag.svg";
import UnitedStatesIcon from "../../assets/home/flags/usFlag.svg";
import Russia from "../../assets/home/flags/ruFlag.svg";
import {
  Country,
  HomeWrapper,
  HomeWrapperSkeleton,
  MainImage,
  MainImageSkeleton,
  Arrow,
  MainImageWrapper,
  SubTitle,
} from "./styles";
import Content from "./components/content";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { decrementSkip, incrementSkip } from "../../redux/features/cardsSlice";
import { setShowHeader } from "../../redux/features/subscribeSlice";
import { getCards, getScrollCards } from "../../redux/api/cardServices";
import { IMG_URL } from "../../constants";

const Home = () => {
  const width = useWidth();
  const dispatch = useAppDispatch();
  const [imageLoaded, setImageLoaded] = useState("show");
  const { cards, loading, skip, countryId } = useAppSelector(
    (state) => state.cards
  );
  useEffect(() => {
    dispatch(setShowHeader(true));
  }, []);
  useEffect(() => {
    dispatch(getCards({ skip, countryId }));
  }, [width]);

  useEffect(() => {
    dispatch(getScrollCards({ countryId }));
    getCountryFlag(countryId)
  }, [countryId]);

  const goLeft = () => {
    skip !== 0 && dispatch(decrementSkip());
    skip !== 0 && setImageLoaded("hidden");
  };

  const goRight = () => {
    cards.length > 1 && dispatch(incrementSkip());
    cards.length > 1 && setImageLoaded("hidden");
  };

  useKey("ArrowLeft", goLeft);
  useKey("ArrowRight", goRight);

  

  const handleCard = (index: number) => {
    dispatch(setSkip(skip + index));
    window.scrollTo(0, 0);
  };

  const getCountryFlag = (countryId: string) => {
    switch (countryId) {
      // Turkey
      case "f88c8e76-6e6a-4859-ac8c-078b4aef0a05":
        return (
          <><img src={TurkeyIcon} alt="turkey" />Turkey</>
        );
      // Singapore
      case "a3ea9513-89f4-4fe0-9ffa-89a9cdcdf084":
        return <><img src={SingaporeIcon} alt="turkey" />Singapore</>;
        // United States
        case "5961be98-9457-4b0f-8724-1d9902244482":
        return <><img src={UnitedStatesIcon} alt="turkey" />United States</>;
        // Russia
        case "6fad7130-fb45-4c36-87e6-6da24c677d3f":
        return <><img src={Russia} alt="turkey" />Russia</>;
      default:
        return TurkeyIcon;
    }
  };

  return loading ? (
    <HomeWrapperSkeleton width={width} className="skeleton" />
  ) : (
    <HomeWrapper width={width}>
      {imageLoaded === "hidden" && <MainImageSkeleton width={width} className="skeleton" />}
      <MainImageWrapper width={width} display={imageLoaded}>
        <MainImage
          display={imageLoaded}
          src={IMG_URL + `${cards[0]?.media}`}
          onLoad={() => setImageLoaded("show")}
        />
        <Country>
          {getCountryFlag(countryId)}
        </Country>
      </MainImageWrapper>
      <Content cards={cards} />
      <SubTitle onClick={() => handleCard(1)} w={width}>{cards[1]?.title}</SubTitle>
      <SubTitle onClick={() => handleCard(2)} w={width}>{cards[2]?.title}</SubTitle>
      <SubTitle onClick={() => handleCard(3)} w={width} lastItem={true}>
        {cards[3]?.title}
      </SubTitle>
      <Arrow onClick={goLeft}>
        <img src={LeftArrow} alt="home-left-arrow" />
      </Arrow>
      <Arrow onClick={goRight} right>
        <img src={RightArrow} alt="home-right-arrow" />
      </Arrow>
    </HomeWrapper>
  );
};

export default Home;
