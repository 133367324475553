import styled from "styled-components";

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 8px;
`;
type InputStyleProps = {
  error: boolean;
};

export const Input = styled.input<InputStyleProps>`
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  border: 1px solid ${(props) => (props.error ? "red" : "#000")};
  border-radius: 16px;
  padding-top: 14px;
  padding-bottom: 14px;
  color: #5a5a5a;
  font-family: Poppins;
  font-weight: 400;
  font-size: 14px;
  ::placeholder {
    color: #c2c2c2;
  }
`;

export const CountryCode = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 1px solid #000;
  border-radius: 16px;
  height: 54px;
  padding-left: 16px;
  padding-right: 16px;
`;

export const CodeSelect = styled.select`
  -moz-appearance: none;
        -webkit-appearance: none;play: none;
  }
  ::-ms-expand {
        display: none;
      }

`;
