import React, { createRef } from "react";
import { Input, InputContainer, CountryCode, CodeSelect } from "./styles";
import dropdownIcon from "../../assets/payment/dropdownIcon.svg";
interface IInput extends React.InputHTMLAttributes<HTMLInputElement> {
  placeholder: string;
  name: string;
  value: string;
  type?: "email" | "text" | "number" | "tel";
  style?: any;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => any;
  selectValue?: string;
  selectHandleChange?: any;
  errors?: boolean;
}
const LabelwithInput = ({
  placeholder,
  name,
  value,
  type = "text",
  style,
  handleChange,
  selectValue,
  selectHandleChange,
  errors = false,
  ...props
}: IInput) => {
  const countryRef = createRef<HTMLSelectElement>();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 8,
        ...style,
      }}
    >
      <label
        style={{
          fontFamily: "Poppins",
          fontWeight: "400",
          fontSize: 12,
          lineHeight: "18px",
          color: "#000",
        }}
      >
        {placeholder}
      </label>
      <InputContainer>
        <Input
          error={errors}
          type={type}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          {...props}
        />
        {name === "phone" && (
          <CountryCode onClick={() => countryRef.current?.focus()}>
            <CodeSelect
              ref={countryRef}
              name="phoneCode"
              value={selectValue}
              onChange={selectHandleChange}
            >
              <option value="90">+90</option>
              <option value="1">+1</option>
              <option value="65">+65</option>
              <option value="44">+44</option>
            </CodeSelect>
            <img src={dropdownIcon} alt="dropdownIcon" height={32} width={32} />
          </CountryCode>
        )}
      </InputContainer>
    </div>
  );
};

export default LabelwithInput;
