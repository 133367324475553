import React from "react";
import { useTranslation } from 'react-i18next';
import { useWidth } from "../../hooks/useWidth";
import SvaypLogo from "../../assets/footer/svaypLogo.svg";
import AppstoreLogo from "../../assets/footer/appstoreLogo.svg";
import GoogleplayLogo from "../../assets/footer/googleplayLogo.svg";
import {
  Wrapper,
  ContentWrapper,
  TrademarkWrapper,
  TrademarkText,
  Container,
  NavWrapper,
  NavItem,
  ImageWrapper,
  MobileContentWrapper,
  MobileTrademarkText,
  MobileImageWrapper,
  MobileNavWrapper,
  MobileNavItem,
} from "./styles";
import { Link } from "react-router-dom";
import i18next from "i18next";

const Footer = () => {
  const width = useWidth();
  
  const { t } = useTranslation(['footer']);

  const footerLinks = [
    { title: t('company-profile') },
    { title: t('data-collection') },
    { title: t('what-we-do') },
    { title: t('legal') },
    { title: t('get-in-touch') },
    { title: t('advertise') },
    { title: t('fields-of-interest') },
    { title: t('public-access') },
    { title: t('contribution') },
    { title: t('privacy-policy'), url: i18next.language === "tr" ? "/gizlilik-politikasi" : (i18next.language === "sg" ? "/privacy-policy" : "/privacy-policy") },
    { title: t('user-agreement'), url: i18next.language === "tr" ? "/kullanici-sozlesmesi" : (i18next.language === "sg" ? "/user-agreement" : "/user-agreement") },
    { title: `${t('version')}: 0.04` },
  ]

  return (
    <Wrapper>
      {width > 425 ? (
        <React.Fragment>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img src={SvaypLogo} alt="svayp-logo" />
            <ImageWrapper>
              <a href={i18next.language === "tr"? "https://apps.apple.com/tr/app/svayp/id6447109874?l=tr": i18next.language === "sg"? "https://apps.apple.com/sg/app/svayp/id6447109874?l=sg": "https://apps.apple.com/us/app/svayp/id6447109874?l=us"}><img src={AppstoreLogo} alt="appstore-logo" width={140} height={48} /></a>
              <a href="https://play.google.com/store/apps/details?id=com.svayp.svayp"> <img src={GoogleplayLogo} alt="playstore-logo" width={140} height={48} /></a>
            </ImageWrapper>
          </div>

          <ContentWrapper>
            <TrademarkWrapper>
              <TrademarkText>
              {t('trademark')}
              </TrademarkText>
              <TrademarkText>SVAYP is at Istanbul 34460 TURKEY</TrademarkText>
            </TrademarkWrapper>
            <Container>
              <NavWrapper>
                {footerLinks.map((link: any) => (
                  <NavItem key={`footer-${link.title}`}>
                    <Link
                      onClick={() => link.url && window.scrollTo(0, 0)}
                      to={link?.url || "#"}
                    >
                      {link.title}
                    </Link>
                  </NavItem>
                ))}
              </NavWrapper>
            </Container>
          </ContentWrapper>
        </React.Fragment>
      ) : (
        <MobileContentWrapper>
          <MobileImageWrapper>
            <img src={SvaypLogo} alt="svayp-logo" width={120} />
          </MobileImageWrapper>
          <MobileTrademarkText>
            {t('trademark')}
          </MobileTrademarkText>
          <MobileImageWrapper>
          <a href={i18next.language === "tr"? "https://apps.apple.com/tr/app/svayp/id6447109874?l=tr": i18next.language === "sg"? "https://apps.apple.com/sg/app/svayp/id6447109874?l=sg": "https://apps.apple.com/us/app/svayp/id6447109874?l=us"}><img src={AppstoreLogo} alt="appstore-logo" width={140} height={48} /></a>
          <a href="https://play.google.com/store/apps/details?id=com.svayp.svayp"> <img src={GoogleplayLogo} alt="playstore-logo" width={140} height={48} /></a>
          </MobileImageWrapper>
          <MobileNavWrapper>
            {footerLinks.map((link: any) => (
              <MobileNavItem key={`footerM-${link.title}`}>
                <Link
                  onClick={() => link.url && window.scrollTo(0, 0)}
                  to={link?.url || "#"}
                >
                  {link?.title}
                </Link>
              </MobileNavItem>
            ))}
          </MobileNavWrapper>
          <MobileTrademarkText>
            SVAYP is at Istanbul 34460 TURKEY
          </MobileTrademarkText>
        </MobileContentWrapper>
      )}
    </Wrapper>
  );
};

export default Footer;
