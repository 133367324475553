import { createSlice } from "@reduxjs/toolkit";
import { formValues } from "../../pages/payment/components/AccountCard";
import { formValuesOrder } from "../../pages/payment/components/OrderCard";

interface ISubscribe {
  label: string;
  color: string;
  price: string;
}
export interface PaymentState {
  subscribePacket: ISubscribe;
  accountCardInfo: formValues;
  orderCardInfo: formValuesOrder;
  offerCardInfo: {
    categoryName: string;
  };
}

const initialState: PaymentState = {
  subscribePacket: {
    label: "",
    color: "",
    price: "",
  },
  accountCardInfo: {
    adSoyad: "",
    email: "",
    phone: "",
    phoneCode: "",
    country: "",
  },
  orderCardInfo: {
    paymnetMethod: "creditCard",
    cardNumber: "",
    cardName: "",
    expiresMonth: "",
    expiresYears: "",
    cvv: "",
    address: "",
    zip: "",
    city: "",
    country: "",
    region: "",
  },
  offerCardInfo: {
    categoryName: "",
  },
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setSubscribePacket: (state, action) => {
      state.subscribePacket = action.payload;
    },
    setAccountCardInfo: (state, action) => {
      state.accountCardInfo = action.payload;
    },
    setOrderCardInfo: (state, action) => {
      state.orderCardInfo = action.payload;
    },
    setOfferCardInfo: (state, action) => {
      state.offerCardInfo = action.payload;
    },
  },
  //   extraReducers: (builder) => {
  //     builder.addCase(getCards.pending, (state) => {
  //       state.loading = true;
  //       state.error = "";
  //     });
  //     builder.addCase(getCards.fulfilled, (state, action) => {
  //       state.cards = action.payload.data;
  //       state.loading = false;
  //     });
  //     builder.addCase(getCards.rejected, (state) => {
  //       state.loading = false;
  //       state.error = "Error fetching cards data!";
  //     });
  //     builder.addCase(getScrollCards.fulfilled, (state, action) => {
  //       state.scrollCards = action.payload.data;
  //     });
  //   },
});

export const {
  setAccountCardInfo,
  setSubscribePacket,
  setOrderCardInfo,
  setOfferCardInfo,
} = paymentSlice.actions;

export default paymentSlice.reducer;
