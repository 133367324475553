import React from "react";

interface IArray {
  label: string;
  value: string;
}
interface IRadio {
  array: IArray[];
  selected: any;
  onChange: any;
  width?: string;
  title: string;
}
const RadioButton = ({
  array,
  onChange,
  selected,
  width = "100%",
  title,
}: IRadio) => {
  return (
    <div
      style={{
        display: "flex",
        width: width,
        gap: 8,
        marginBottom: 32,
        flexDirection: "column",
      }}
    >
      <h4
        style={{
          alignItems: "center",
          fontFamily: "Poppins",
          fontSize: 14,
          fontWeight: "400",
          color: "#5A5A5A",
        }}
      >
        {title}
      </h4>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 52,
        }}
      >
        {array.map((item, index) => (
          <label
            key={index}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "Poppins",
              fontSize: 14,
              fontWeight: "400",
            }}
          >
            <input
              type="radio"
              value={item.value}
              checked={selected === item.value}
              onChange={onChange}
              style={{
                marginRight: 8,
                width: 32,
                height: 32,
              }}
            />
            {item.label}
          </label>
        ))}
      </div>
    </div>
  );
};

export default RadioButton;
