import styled from "styled-components";

export const ContentWrapper = styled.div<{ width: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(${(props) => (props.width / 3.3) * 2.3}px - 80px);
  padding: 12px;
  padding-bottom: 30px;

  @media screen and (max-width: 425px) {
    width: 100%;
    padding: 16px;
    padding-bottom: 100px;
    border-bottom: 1.5px solid black;
  }

  overflow: hidden;

  /* border: 1px solid green; */
`;
