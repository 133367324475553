import { useRef, useEffect } from 'react'

export const useKey = (key: string, cb: any) => {
  const callbackRef = useRef(cb)

  useEffect(() => {
    callbackRef.current = cb
  })

  useEffect(() => {
    function handle(event: any) {
      if (event.code === key) {
        callbackRef.current(event)
      }
    }
    document.addEventListener('keydown', handle)
    return () => document.removeEventListener('keydown', handle)
  }, [key])
}
