import { FC } from "react";
import { useWidth } from "../../../../hooks/useWidth";
import { ContentWrapper } from "./styles";
import ContentHeader from "../contentHeader";
import Rates from "../rates";
import SocialShare from "../socialShare";
import LiveVotes from "../liveVotes";
import { Card } from "../../../../types/card";

interface ContentProps {
  cards: Card[];
}

const Content: FC<ContentProps> = ({ cards }) => {
  const width = useWidth();

  return (
    <ContentWrapper width={width}>
      <ContentHeader cards={cards} />
      <Rates card={cards[0]} />
      <SocialShare createdAt={cards[0]?.releaseStartDate} />
      <LiveVotes />
    </ContentWrapper>
  );
};

export default Content;
