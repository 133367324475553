import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding-top: 47px;
  background: #000;
  @media screen and (max-width: 425px) {
    height: 100%;
    max-height: 100%;
    width: 100%;
  }
`;

export const Page = styled.div`
  width: 55%;
  color: white;
  margin-top: 24px;
  margin-bottom: 24px;
  @media screen and (max-width: 425px) {
    width: 85%;
  }
`;

export const Title = styled.h1`
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 16px;
  text-transform: uppercase;
  @media screen and (max-width: 425px) {
    font-size: 24px;
    line-height: 16px;
  }
`;

export const Content = styled.p`
  font-size: 14px;
  white-space: pre-line;
  line-height: 21px;
`;