import { FC, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useWidth } from "../../../../hooks/useWidth";
import GreenArrow from "../../../../assets/home/greenArrow.svg";
import RedArrow from "../../../../assets/home/redArrow.svg";
import GrayArrow from "../../../../assets/home/grayArrow.svg";
import {
  Container,
  GrayContainer,
  GrayWrapper,
  GreenArrowImage,
  GreenDiv,
  GreenRateState,
  GreenRateWhole,
  ImageWrapper,
  RatesWrapper,
  RedArrowImage,
  RedDiv,
  RedRateState,
  RedRateWhole,
  Wrapper,
  GrayArrowImage,
  VoteCount,
} from "./styles";
import { Card } from "../../../../types/card";
import { Tooltip } from "react-tooltip";
import AnimatedNumber from "react-awesome-animated-number";
import "react-awesome-animated-number/dist/index.css";


interface RatesProps {
  card: Card;
}

const Rates: FC<RatesProps> = ({ card }) => {
  const {t} = useTranslation(['home']);
  const width = useWidth();

  useEffect(() => {
    const wsUrl = 'wss://api.svayp.com/ws';

    const ws = new WebSocket(wsUrl);

    ws.onopen = () => {
      // console.log('WebSocket bağlantısı başarıyla açıldı.');
    };

    ws.onmessage = (event) => {
      const receivedData = JSON.parse(event.data);

    
      if (receivedData.cardId === card?.id) {
        switch (receivedData.votePosition) {
          case 'left':
            setLeftStat((prevLeftStat) => prevLeftStat + 1);
            break;
          case 'right':
            setRightStat((prevRightStat) => prevRightStat + 1);
            break;
          case 'top':
            setTopStat((prevTopStat) => prevTopStat + 1);
            break;
          default:
            break;
        }
      }
    };
    

    ws.onclose = () => {
      // console.log('WebSocket bağlantısı kapatıldı.');
    };

    return () => {
      ws.close();
    };
    // eslint-disable-next-line
  }, [ ]);


  const [rightStat, setRightStat] = useState(card?.optionRightStat);
  const [leftStat, setLeftStat] = useState(card?.optionLeftStat);
  const [topStat, setTopStat] = useState(card?.optionTopStat);

  const [GreenRate, setGreenRate] = useState(0);
  const [RedRate, setRedRate] = useState(0);
  const [GreyRate, setGreyRate] = useState(0);

  useEffect(() => {
    const totalRate =
    leftStat + rightStat + topStat;
  let GreenRate = rightStat
    ? (rightStat * 100) / totalRate
    : leftStat || topStat ? 0 : 50;
  let RedRate =leftStat
    ? (leftStat * 100) / totalRate
    : rightStat || topStat ? 0 : 50;
  let GreyRate = topStat
    ? (topStat * 100) / totalRate
    : 0;

  setGreenRate(GreenRate);
  setRedRate(RedRate);
  setGreyRate(GreyRate);
  }
  , [rightStat, leftStat, topStat]);



  const _gRate = GreenRate + GreyRate / 2;
  const _rRate = RedRate + GreyRate / 2;
  const gRate = () => (_gRate > 75 ? 75 : _gRate < 25 ? 25 : _gRate);
  const rRate = () => (_rRate > 75 ? 75 : _rRate < 25 ? 25 : _rRate);

  let factor = 1;
  if (width > 1440) {
    factor = 2 - (2560 - width) * 0.000892857143;
  } else if (width > 1024) {
    factor = 1 - (1440 - width) * 0.000892857143;
  } else if (width > 768) {
    factor = 1;
  } else {
    factor = 0.8;
  }


  return (
    <Wrapper>
      <Container>
        <RatesWrapper className="my-anchor-element">
          <GreenDiv h={_gRate} kat={factor}>
            <GreenRateWhole h={gRate()} kat={factor}>
            <AnimatedNumber value={leftStat === 0 && rightStat === 0 ? 0 : parseFloat(GreenRate.toFixed(1))} size={(1 + gRate() * 0.02) * factor * 16} />%
            </GreenRateWhole>
            <GreenRateState h={gRate()} kat={factor}>
              {card?.optionRightName}
            </GreenRateState>
            <VoteCount>
            <AnimatedNumber value={rightStat} size={16} />&nbsp;{t('vote')}
            </VoteCount>
          </GreenDiv>

          <ImageWrapper w={8} h={11} kat={factor}>
            <GreenArrowImage className="grow-from-left" src={GreenArrow} h={_gRate} kat={factor} />
            <RedArrowImage src={RedArrow} h={_rRate} kat={factor} />
          </ImageWrapper>

          <RedDiv h={_rRate} kat={factor}>
            <RedRateWhole h={rRate()} kat={factor}>
            <AnimatedNumber value={leftStat === 0 && rightStat === 0 ? 0 : parseFloat(RedRate.toFixed(1))} size={(1 + rRate() * 0.02) * factor * 16} />%
            </RedRateWhole>
            <RedRateState h={rRate()} kat={factor}>
              {card?.optionLeftName}
            </RedRateState>
            <VoteCount>
            <AnimatedNumber value={leftStat} size={16} />&nbsp;{t('vote')}
            </VoteCount>
          </RedDiv>
          <Tooltip anchorSelect=".my-anchor-element" place="top" openOnClick={true}>
          {t('tooltip_click_vote')}
          </Tooltip>
        </RatesWrapper>
        <GrayWrapper>
          <GrayContainer>
            <GrayArrowImage
              src={GrayArrow}
              alt="gray-arrow"
              kat={factor * 0.7}
              h={1}
            />
            <p><AnimatedNumber value={parseFloat(GreyRate.toFixed(1))} size={16} />%</p>
            <p>{card?.optionTopName}</p>
            <VoteCount>
            <AnimatedNumber value={topStat} size={16} />&nbsp;{t('vote')}
            </VoteCount>
          </GrayContainer>
        </GrayWrapper>
      </Container>
    </Wrapper>
  );
};

export default Rates;
