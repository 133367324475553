import { Routes, Route, Outlet } from "react-router-dom";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import { ScrollCards } from "./components/scroll";
import { useAppSelector } from "./redux/store";

// Import pages

import Home from "./pages/home";
import Subscribe from "./pages/subscribe";
import Payment from "./pages/payment";
import PrivacyPolicy from "./pages/privacy-policy/index";
import UserAgreement from "./pages/user-agreement/index";
import GizlilikPolitikasi from "./pages/gizlilik-politikasi";
import KullaniciSozlesmesi from "./pages/kullanici-sozlesmesi";

function App() {
  const { isShowHeader } = useAppSelector((state) => state.subscribe);
  return (
    <div>
      <Routes>
        {/* With Navbar Routes */}
        <Route
          element={
            <>
              {isShowHeader && <ScrollCards />}
              <Navbar />
              <Outlet />
            </>
          }
        >
          <Route path="/" element={<Home />} />
        </Route>

        {/* Without Navbar Routes */}
        <Route path="subscribe" element={<Subscribe />} />
        <Route path="payment" element={<Payment />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="gizlilik-politikasi" element={<GizlilikPolitikasi />} />
        <Route path="user-agreement" element={<UserAgreement />} />
        <Route path="kullanici-sozlesmesi" element={<KullaniciSozlesmesi />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
