import { ContentStyle } from './style';

const Turkish = () => {
  return (
    <ContentStyle>
        <p>&nbsp;</p>
        <p>İşbu sözleşme ile veri sahipleri ve kullanıcılar, temsilcisinin <strong>TARKAN KAYA</strong> olduğu (tarkan.kaya@gmail.com / tarkan@svayp.com) “<strong><em>www.svayp.com” </em></strong>uzantılı web sitesine, aplikasyonlarına, mobil sürümlerine ve sair sistemleri tarafından verilerinin aydınlatma metninde belirtildiği şekilde işleneceğine açık rıza vermiş olur. 6698 sayılı Kişisel Verilerin Korunması Hakkında Kanun’un (“KVKK”) ilgili hükümlerinde öngörülen AÇIK RIZA, işbu sözleşmenin onaylanması ile verilir.</p>
        <p>Bununla beraber; <strong><em>SVAYP</em></strong>’ın kullanım şartları ve ticari koşullar da işbu sözleşmede düzenlenmiş olup; karşılıklı ve birbirine uygun irade açıklamalarının kurulmasıyla sözleşmede düzenlenen koşullar bağlayıcılık kazanır.</p>
        <p>İşbu sözleşme, aydınlatma metninin eki mahiyetindedir<strong>.<span>&nbsp; </span></strong>Şu kadar ki, usulüne uygun olarak yürürlüğe konmasının akabinde kişisel veriler aydınlatma metninde belirtilen şekilde işlenmeye başlar; kararlaştırılan ticari koşullar bağlayıcılık kazanır. Sözleşmede ayrıca değinilmeyen hususlar hakkında yürürlükteki mevzuatın emredici ve düzenleyici hükümleri esas alınır.</p>
        <p>&nbsp;</p>
        <p style={{textAlign: 'center'}}><span><strong>K O Ş U L L A R</strong></span></p>
        <p>&nbsp;</p>
        <p><strong><em>Taraflar</em></strong></p>
        <p><strong>MADDE 1.- </strong>(1) İşbu sözleşme, “öneren” ve “veri sorumlusu” sıfatlarını haiz <strong><em>SVAYP</em> </strong>ile, “kabul eden” sıfatlı “<strong>VERİ SAHİBİ (KULLANICI)”</strong> arasında akdedilmiş olup, hüküm ve sonuçlarını dürüstlük kuralları çerçevesinde doğurur.</p>
        <p>(2) Sözleşme, usulüne uygun karşılıklı rızaların varlığı ile geçerlik kazanır. Usûl, kullanıcının sözleşmeyi <strong>elektronik</strong> ya da <strong>fiziki</strong> ortamda kabul ettiğini açıklar irade beyanı ile yerine getirilmiş olur. Elektronik ortamdaki rıza, <strong><em>SVAYP</em></strong>’ın sunduğu onay butonunun işaretlenmesi ile açıklanır.</p>
        <p>(3) 6698 sayılı Kanun m. 3 /1 – ç hükmü uyarınca tüzel kişiler veri sahibi sayılmadığından, işbu sözleşme ile verilecek açık rıza (veri işlenmesine yönelik) yalnız gerçek kişiler bakımından hüküm ve sonuç doğurur. Tüzel kişi kullanıcılar, sözleşmenin yalnız ticari hükümleri ile bağlıdır.</p>
        <p><strong><em>Sözleşmenin Konusu, Kapsamı ve Hukuki Niteliği</em></strong></p>
        <p><strong>MADDE 2.- </strong>(1) Sözleşme, tarafların hak ve yükümlülüklerinin hüküm altına alınmasına, 6098 s. Türk Borçlar Kanunu ve 6698 s. KVKK kapsamındaki <strong>aydınlatma yükümlülüğü</strong>nün yerine getirilmiş olduğuna ve veri sahiplerinin verilerinin işlenmesi bakımından gereken <span><strong>açık rızalarının olduğu</strong></span> hususlara dair esasları içerir.</p>
        <p>(2) Sözleşme, değindiği her konuda özü ve sözüyle tarafları bağlar. Bunun yanında, sözleşme ile değinilmeyen hususlar hakkında, başta 6698 s. Kişisel Verilerin Korunması Kanunu, GVKT (Avrupa Birliği Genel Veri Koruma Tüzüğü) ve 6098 s. Türk Borçlar Kanunu’nun ilişkili hükümleri olmak üzere sair yasal mevzuatın emredici ve düzenleyici hükümleri uygulanır. GVKT, 6698 s. KVKK’ye uygun düştüğü ölçüde uygulanır.</p>
        <p><span><strong><em>Tarafların<span>&nbsp; </span>Hak ve Yükümlülükleri</em></strong></span></p>
        <p><span><strong>MADDE 3.- </strong>(1)</span> Veri sorumlusu, verileri hukuka uygun şekilde ve aydınlatma metninde belirtilen şekilde işleyeceğini kabul, beyan ve taahhüt eder.</p>
        <p>(2) Veriler, veri sahibinin açık rızasına dayalı olarak işlenebilir. Açık rıza, aydınlatma protokolünde yazılı işleme şekillerine ilişkin olarak verilir.</p>
        <p>(3) Veriler, aydınlatma metninde de belirtildiği üzere, şu şekillerde işlenir:</p>
        <ul>
          <li><strong><em>SVAYP,</em></strong> sözü edilen kişisel verileri, ticari amaçlarına uygun olarak, bu amaçlar doğrultusunda ve ölçülü olarak işler. Bununla birlikte; <strong><em>SVAYP’a </em></strong>yapılan girişler ve kayıtlar sırasında kullanıcı adı ve şifre oluşturulması gerekecek olup, oluşturulan şifre ve kullanıcı adları otomatik olarak yazılıma kaydedilir ve depolanarak işlenir. Kayıt için veri sahiplerinin <strong>telefon numaraları ve sair kişisel bilgileri de talep edilebilecek olup,</strong> numara ve sair kişisel veriler <strong><em>SVAYP’a</em></strong> ait güvenli yazılımda tutulur. Bunların talep edilmemesi de mümkün olup, takdir yetkisi <strong><em>SVAYP</em></strong>’a aittir. <strong><em>SVAYP, </em></strong>6698 s. KVKK’ye uygun olarak ölçülü şekilde hareket etmeyi ve ancak aydınlatma metninde belirtilen amaçları doğrultusunda veri işleyeceğini taahhüt eder.</li>
        </ul>
        <ul>
          <li><strong><em>SVAYP,</em></strong> sözü edilen kişisel verileri, kamuoyu araştırması (anket) yapmak, sonuçları bilimsel ve istatiksel veri haline getirerek kamuoyu ile paylaşmak ve bu sonuçları üçüncü kişilere aktarmak gibi amaçlar doğrultusunda işler. <strong><em>SVAYP</em></strong>, bilgi ve verileri yasal şartlara bağlı olarak toplayacak, kullanacak, belirli bir süre ile saklayacak, üçüncü kişilere aktaracak, anonimleştirerek anket haline getirecek ve yukarıda yazılı her şekilde işleyebilecektir. Bir diğer söyleyişle; <span><strong><em>SVAYP </em>üzerinden beyan edilecek kişisel görüş ve tercihler kullanıcıların ad ve soyadlarının gizli tutulması ve anonimleştirilmesi suretiyle bir anket haline getirilir; bu şekilde ticaret şirketlerinin ve sair üçüncü kişilerin bu verilere dayanarak stratejik planlama faaliyetlerinin yürütülmesine katkıda bulunulur.</strong></span> Oluşturulan anketler ve depolanan kişisel veriler, anonimleştirilerek üçüncü kişilere, topluluk şirketlerine ya da sair kimselere aktarılır.</li>
        </ul>
        <ul>
          <li>Veri sahipleri, <strong><em>SVAYP’a</em></strong> üye olmalarını izleyen aşamalarda, talepleri ve rızaları doğrultusunda muhtelif kişisel bilgilerini herkese açık olarak paylaşabilir. Bu halde veriler ALENİ hale getirilmiş sayılacak olup, <strong><em>SVAYP</em></strong> bu verileri 6698 s. Kanun’un 5’inci maddesi doğrultusunda açık rıza aramaksızın işleyebilir.</li>
        </ul>
        <ul>
          <li>Veri sahiplerinin <strong><em>SVAYP</em></strong>‘<strong><em>a</em></strong> “üye” olarak kaydolmalarından önce ve sonra, kullandıkları cihazın sağladığı teknik veriler vasıtasıyla edinilen <span><strong>tarayıcı ve çerez bilgileri</strong></span> <strong><em>SVAYP </em></strong>tarafından işlenmeye başlar, <strong><em>SVAYP</em></strong> tarafından kullanıcıların cihazlarına çerez yerleştirilir. Buna ilişkin bir uyarı metni web sitesine giriş ile birlikte kullanıcılara sunulur. Web sitesinin kullanımı için <strong>zorunlu olan çerezler, kullanıcının web sitesine girdiği an kullanılmaya başlar</strong>. Bunun için<span>&nbsp; </span>kullanıcının açık rızasına gerek duyulmaz. Kullanıcıya, web sitesinin kullanılması ve sitenin işlemesi için zorunlu olan çerezlerin web sitesine giriş yapıldığı anda kullanılmaya başlandığı derhal bildirilir. Bu bildirim, karşılama sayfasına eklenecek bir uyarı ile yapılır. Ancak, işlevsel veya reklam amaçlı çerez kullanımları ancak açık rızanın varlığı ile kullanılabilir. Buna ilişkin ayrıntılar, işbu sözleşmenin eki mahiyetinde olan aydınlatma metninde açıklanmıştır.</li>
        </ul>
        <ul>
          <li>Veri sahipleri, <strong><em>SVAYP’ı</em></strong> kullandıkları sırasında muhtelif ticari reklamlar ile karşılaşabilir. <strong><em>SVAYP</em></strong> dışındaki sair uygulamalar aracılığıyla gerçekleşen işbu reklamlar, reklam verenin ve reklam sorumlusunun sorumluluğunda olup, <strong><em>SVAYP</em></strong> işbu reklamlar bakımından sorumlu değildir.</li>
        </ul>
        <p>&nbsp;</p>
        <p>(4) <span><strong><em>SVAYP</em></strong></span><strong><em>, </em></strong>şu hallerin varlığı durumunda <strong>açık rıza aramaksızın </strong>kişisel verileri işleyebilir:</p>
        <ul>
          <li>Kanunlarda açıkça öngörülmesi</li>
        </ul>
        <ul>
          <li>Kişinin, fiili imkansızlık nedeniyle rızasını açıklayamayacak durumda olması (varsayılan rıza hali)</li>
          <li>Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin zorunlu olması</li>
        </ul>
        <ul>
          <li>Veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için veri işlemesinin mecburi olması</li>
        </ul>
        <ul>
          <li><strong><em>Veri sahibinin verilerini </em></strong><span><strong><em>ALENÎ</em></strong></span><strong><em> hale getirmiş olması</em></strong></li>
        </ul>
        <ul>
          <li>Veri sahibinin temel haklarına zarar vermemek kaydıyla, <span><strong>veri sorumlusunun meşru menfaatleri</strong></span> için veri işlemesinin zorunlu olması</li>
        </ul>
        <p>&nbsp;</p>
        <p>(5) Kişisel veriler; <span><strong>işlenme sebeplerinin ortadan kalkması halinde</strong></span> veri sorumlusu tarafından re’sen veya ilgili kişinin talebi halinde silinir, yok edilir ya da anonim hale getirilir.</p>
        <p><span>(6)<strong><em> SVAYP</em></strong></span>, web sitesi ve aplikasyonları üzerinde serbestçe değişiklik yapabilir, iş ve işlemlerin yönetimini dilediği şekilde gerçekleştirebilir. Paylaşım kurallarını dilediği gibi değiştirebilir, mevcut kuralları ilga edebilir. Bu husustaki değişiklikleri kullanıcılarına bildirmek zorunluluğu bulunmamaktadır.</p>
        <p>(7) Veri sahibi, <strong><em>SVAYP’ı</em></strong> hukuka uygun iş ve işlemleri için kullanacağını, işbu sözleşmeye, yürürlükteki mevzuata ve öngörülen diğer hüküm ve koşullara uygun davranacağını kabul, beyan ve taahhüt eder. <strong><em>SVAYP</em></strong><span> aracılığıyla işlenen suçlar </span><strong><em>SVAYP</em></strong><span> yöneticilerini ve organ temsilcilerini ilgilendirmez; doğacak zararların </span><strong><em>SVAYP</em></strong><span> tarafından tazmini talep edilemez.</span></p>
        <p>(8) Veri sahibinin şu hakları saklıdır:</p>
        <p>&nbsp;</p>
        <ul>
          <li>Kişisel verilerin işlenip işlenmediğini öğrenme,</li>
          <li>İşlenmişse buna ilişkin bilgi talep etme,</li>
          <li>Verilerin işlenme amacını ve bunların amaca uygun kullanılıp kullanılmadığını öğrenme,</li>
          <li>Verilerin aktarıldığı kişileri öğrenme,</li>
          <li>Verilerin eksik ya da yanlış işlenmesi durumunda bunların düzeltilmesini isteme,</li>
          <li><strong><em>Şartları oluştuğunda kişisel verilerin silinmesini veya yok edilmesini isteme</em></strong>, (Verilerin işlenmesine ilişkin rızanın geri alınması, ileriye etkili olarak sonuç doğuracak olup; geri alma anına değin gerçekleştirilen işlemeler hukuka uygunluğunu sürdürecektir.)</li>
          <li>Düzeltme, silme ya da yok etme işlemlerinin verilerin aktarıldığı üçüncü kişilere iletilmesini isteme,</li>
          <li>İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,</li>
          <li>Kişisel verilerin kanuna aykırı olarak işlenmesi durumunda meydana gelecek zararların tazminini talep etme</li>
        </ul>
        <p>(9) <span><strong>Veri sahibi, yukarıda sayılı işleme şekillerine ve aydınlatma metninde tarif edilen işleme usullerine açık rıza gösterdiğini kabul ve beyan eder.</strong></span> Bunun yanında, açık rızasının aydınlatma protokolüne bağlı olarak BİLGİLENDİRMEYE DAYALI OLDUĞUNU, hangi hususlar hakkında açık rıza verdiğinin BELİRLİ OLDUĞUNU ve rızasının irade fesadı halleriyle SAKAT OLMADIĞINI kabul ve beyan eder.</p>
        <p>(10) Kullanıcının işbu sözleşme ve yazılım kapsamında yer alan diğer koşullar<span>&nbsp; </span>ile bu kapsamdaki beyan ve taahhütlerine aykırı davranması halinde <strong><em>SVAYP</em></strong>, kullanıcının üyeliğini askıya alma veya sözleşmeyi feshederek kullanıcının tüm hak ve yetkilerini sona erdirme hakkına sahiptir. Böyle bir durumda, söz konusu aykırılıktan doğan zararlarının tazmini talep edilebilir.&nbsp;</p>
        <p><span><strong><em>Fikri ve Sınai Mülkiyet Hakkı</em></strong></span></p>
        <p><strong>MADDE 4.- </strong>(1) <strong><em>SVAYP’ın</em></strong> her tür ögesinin (arayüz, yazılım, hizmetler, düşünsel eserler, tasarımlar ve diğer her türlü fikri ve teknik anlamda ‘eser’ statüsüne giren ögelerin) fikri mülkiyet hakkı veri sorumlusuna veya eserin sahibine aittir. İzinsiz kopyalanması, taşınması, aktarılması veya farklı bir yolla çoğaltılması işbu sözleşmeye ve fikri mülkiyet haklarına halel getirir.</p>
        <p>(2) Bu hükme aykırılık halinde, 5846 s. Fikir ve Sanat Eserleri Kanunu başta olmak üzere, 6098 s. Türk Borçlar Kanunu’nun tazminat hükümleri ve keza 5237 s. Türk Ceza Kanunu’nun ilgili fiile uygun cezai hükümleri uygulama alanı bulur.</p>
        <p><span><strong><em>Çeşitli Sorumluluklar</em></strong></span></p>
        <p><strong>MADDE 5.- </strong>(1) <strong><em>SVAYP</em></strong>’ın web sitesi ve aplikasyonları kapsamındaki tüm içerikler “<strong><em>olduğu gibi</em></strong>” sunulmakta olup, sunulan<span>&nbsp; </span>içeriğin tamlığı ve güvenilirliği ile ilgili herhangi bir sorumluluk ya da yükümlülüğü bulunmamaktadır.</p>
        <p>(2) Kullanıcı, <strong><em>SVAYP’</em></strong>ı kullanması sırasında gerçekleştirdiği her türlü eylemden, yüklediği her türlü içerikten ve her türlü hukuka aykırı fiilinden kişisel olarak ve münhasıran sorumludur.</p>
        <p><span><strong><em>Sözleşmenin Yürürlüğü ve Feshi</em></strong></span></p>
        <ul>
          <li><strong><em>Sözleşmenin Yürürlüğü</em></strong></li>
        </ul>
        <p><strong>MADDE 6.- </strong><span>&nbsp; </span>Bu sözleşme, tarafların herhangi bir kanal vasıtasıyla verecekleri onay ile birlikte yürürlüğe girer. Yürürlükten kaldırılmadıkça geçerliğini sürdürür. Onay, elektronik ortamda verilecek olursa, onaylama butonunun işaretlenmesi sözleşmenin onaylandığı anlamına gelir. <strong><em>SVAYP’</em></strong><span>ın onayı ise, işbu sözleşmeyi kullanıcıların onayına sunmakla var kabul edilir.</span></p>
        <ul>
          <li><strong><em>Sözleşmenin Feshi </em></strong></li>
        </ul>
        <p><strong>MADDE 7.-<span>&nbsp; </span></strong>(1) Taraflardan herhangi biri, yazılı bir bildirimle işbu sözleşmeyi fesih tarihinden 1 gün önce bildirmek kaydıyla feshedebilir. Fesih, karşı tarafa varmakla sonuç doğurur, şekle bağlı olmaksızın herhangi bir bildirim şekliyle yapılabilir. Ağır bir fesih sebebi meydana geldiğinde ihbar öneli verilmeksizin derhal fesih cihetine gidilebilir.</p>
        <p>(2) Sözleşmenin feshi tarafların fesih tarihine kadar doğmuş olan hak ve yükümlülüklerini ortadan kaldırmaz.</p>
        <p>(3) Bunun yanında, fesih bildirimi kişisel verilerin işlenmesine ilişkin rızanın geri alınması anlamına gelmekte olup, fesih bildiriminin veri sorumlusuna ulaştığı andan itibaren veri işlenmesi mümkün değildir. <span><strong>Ancak fesih ileri etkili sonuç doğurmakta olup; fesihten önceki veri işlemeleri geçerliliğini sürdürür.</strong></span></p>
        <p>(4) Kullanıcı, 6698 s. KVKK’ye ve bu sözleşmeye uygun olarak işlenen kişisel verilerinin imha edilmesini, ancak <span><strong>işlenmesi için gereken amaçların ortadan kalkması ve işlemenin dürüstlük kuralları çerçevesinde işlevsiz hale gelmesi durumunda isteyebilir</strong></span>. Ancak, yanlış ya da eksik işlenen verilerin imha edilmesini veya düzeltilmesini talep edebileceği gibi; işlenen verileri ve işleme şekilleri hakkında bilgi talep etmesi de mümkündür. İletişim için : tarkan@svayp.com<span>.</span></p>
        <p><span><strong><em>Diğer Hükümler</em></strong></span></p>
        <p><span><strong>MADDE 8.-</strong> Sözleşmede yer alan koşullara aykırı davranılması sebebiyle </span><strong><em>SVAYP’ın</em></strong> maddi zarara uğraması durumunda, uğranan zararın 6098 sayılı Türk Borçlar Kanunu hükümleri doğrultusunda tazmini talep edilebilir. Tazminat, zarara yol açan kullanıcı ya da ilgili kişi tarafından ödenir ve tazminat bedeline haksız fiil tarihinden itibaren işlemek üzere 3095 sayılı Kanun uyarınca <em>(Aralık 2020 için : %9)</em> yasal faiz uygulanır. Bilahare akdi faiz kararlaştırılabilir. Aşkın zarara ilişkin tazminat hakkı ile temerrüt faizinden doğacak haklar ve ticari işler bakımından uygulanacak münhasır hükümlerden doğacak hak ve yükümlülükler saklıdır.</p>
        <p><strong>MADDE 9.- </strong>Bu sözleşmedeki hükümlerden herhangi birinin yürürlükteki emredici hukuki normlara aykırı olması halinde, aykırı olan hüküm dışındaki diğer hükümlerle sözleşme yürürlüğünü sürdürür.</p>
        <p><strong>MADDE 10.-</strong> İşbu sözleşmenin değinmediği hususlarda <strong>Aydınlatma Metni</strong>’nin hükümleri, bunun da değinmediği hususlarda ilgili yasal mevzuat esas alınır.</p>
        <p><strong>MADDE 11.-</strong> <span>&nbsp; </span>İşbu sözleşme ve eki mahiyetindeki <strong>Aydınlatma Metni</strong>’nden kaynaklanan uyuşmazlıklarda <strong><em>SVAYP</em></strong> yetkilisinin yerleşim yerindeki mahkemeler ve icra daireleri yetkili sayılır.</p>
      </ContentStyle>
  )
}

export default Turkish