import { useTranslation } from 'react-i18next';
import { PageWrapper, Wrapper, Page, Title, Content } from "./styles";
import SubscribeHeader from "../../components/subscribeHeader";

import TextSlider from "./textSlider";

import Turkish from './content/Turkish';
import English from './content/English';

const UserAgreement = () => {
  const { t } = useTranslation(["userAgreement"]);

  const content = () => {
    switch (t("content")) {
      case "tr":
        return <Turkish />;
      default:
        return <English />;
    }
  };
  return (
    <Wrapper>
      <PageWrapper>
        <SubscribeHeader />
        <Page>
        <TextSlider />
        <Title> {t("title")}</Title>
          <Content>{content()}</Content>
        </Page>
      </PageWrapper>
    </Wrapper>
  );
};

export default UserAgreement;
