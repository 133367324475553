import { createSlice } from "@reduxjs/toolkit";
import { getCards, getScrollCards } from "../api/cardServices";
import { Card } from "../../types/card";

export interface CardsState {
  cards: Card[];
  scrollCards: Card[];
  meta: any;
  loading: boolean;
  error: any;
  skip: number;
  countryId: string;
}

const currentCountryId: any = () => {
  switch (localStorage.getItem("language")) {
    case "en":
      return "5961be98-9457-4b0f-8724-1d9902244482";
    case "us":
      return "5961be98-9457-4b0f-8724-1d9902244482";
    case "tr":
      return "f88c8e76-6e6a-4859-ac8c-078b4aef0a05";
    case "sg":
      return "a3ea9513-89f4-4fe0-9ffa-89a9cdcdf084";
    case "no":
      return "baba323d-b765-4c75-8895-d4785bc6807c";
    default:
      return "f88c8e76-6e6a-4859-ac8c-078b4aef0a05";
  }
};

const initialState: CardsState = {
  cards: [],
  scrollCards: [],
  meta: null,
  loading: true,
  error: "",
  skip: 0,
  countryId: currentCountryId(),
};

export const cardsSlice = createSlice({
  name: "cards",
  initialState,
  reducers: {
    incrementSkip: (state) => {
      state.skip += 1;
    },
    decrementSkip: (state) => {
      state.skip -= 1;
    },
    resetSkip: (state) => {
      state.skip = 0;
    },
    setSkip: (state, action) => {
      state.skip = action.payload;
    },
    setCountryId: (state, action) => {
      state.countryId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCards.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getCards.fulfilled, (state, action) => {
      state.cards = action.payload.data;
      state.loading = false;
    });
    builder.addCase(getCards.rejected, (state) => {
      state.loading = false;
      state.error = "Error fetching cards data!";
    });
    builder.addCase(getScrollCards.fulfilled, (state, action) => {
      state.scrollCards = action.payload.data;
    });
  },
});

export const {
  incrementSkip,
  decrementSkip,
  resetSkip,
  setCountryId,
  setSkip,
} = cardsSlice.actions;

export default cardsSlice.reducer;
