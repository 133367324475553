import styled from "styled-components";

export const HomeWrapper = styled.div<{ width: number }>`
  position: relative;
  min-height: ${(props) => (props.width / 3.3) * 1.33}px;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 425px) {
    width: 100%;
    height: auto;
    flex-direction: column;
    background-color: white;
  }
`;

export const HomeWrapperSkeleton = styled.div<{ width: number }>`
  min-height: ${(props) => (props.width / 3.3) * 1.33}px;
  display: block;
  background-color: #ddd;

  @media screen and (max-width: 425px) {
    height: 100vh;
  }
`;

export const MainImageWrapper = styled.div<{
  width: number;
  display?: string;
}>`
  width: ${(props) => props.width / 3.3}px;
  min-height: ${(props) => (props.width / 3.3) * 1.33}px;
  display: ${(props) => (props.display === "show" ? "flex" : "none")};

  @media screen and (max-width: 425px) {
    position: relative;
    width: 100%;
    height: ${(props) => props.width * 1.05}px;
  }
`;

export const MainImage = styled.img<{ width?: number; display: string }>`
  width: 100%;
  height: 100%;
  display: ${(props) => (props.display === "show" ? "flex" : "none")};
  object-fit: cover;
`;

export const Country = styled.div`
  display: none;
  font-size: 14px;
  font-weight: bold;
  color: white;
  position: absolute;
  left: 16px;
  bottom: 22px;
  @media screen and (max-width: 425px) {
    display: flex;
    gap: 15px;
    align-items: center;
  }
  img{
    width: 32px;
    height: 32px;
    border-radius: 999px;
  }
`;

export const MainImageSkeleton = styled.div<{ width: number }>`
  width: ${(props) => props.width / 3.3}px;
  min-height: ${(props) => (props.width / 3.3) * 1.33}px;
  display: inline-block;
  background-color: #ddd;

  @media screen and (max-width: 425px) {
    width: 100%;
    height: ${(props) => props.width * 1.05}px;
  }
`;

export const Arrow = styled.div<{ right?: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 50%;
  right: ${(props) => (props.right ? "0px" : null)};

  @media screen and (max-width: 425px) {
    top: 30vh;
  }
`;

export const SubTitle = styled.h2<{ w: number; lastItem?: boolean }>`
  display: none;
  font-size: 12px;
  padding: 8px 0px;
  font-weight: normal;
  border-bottom: 1px solid #c2c2c2;
  color: #c2c2c2;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  @media screen and (max-width: 425px) {
    width: calc(100%-32px) px;
    display: flex;
    margin: 0px 16px;
    margin-bottom: ${(props) =>
      props.lastItem && props.lastItem === true ? "24px" : "0px"};
  }
`;
