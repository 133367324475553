import { FormikProps, useFormik } from "formik";
import { useDispatch } from "react-redux";
import Button from "../../../../components/button";
import LabelwithInput from "../../../../components/input";
import { Size, useWindowSize } from "../../../../hooks/useWindowSize";
import { setOfferCardInfo } from "../../../../redux/features/paymentSlice";
import { useAppSelector } from "../../../../redux/store";
import {
  Container,
  FormContainer,
  FormSubmitContainer,
  FormSubmitParagraph,
  FormTitle,
  Header,
  HeaderContainer,
} from "./styles";

interface formValuesOffer {
  categoryName: string;
}
const OfferCard = ({
  setOpen,
  setOtherCard,
}: {
  setOpen: any;
  setOtherCard: any;
}) => {
  const dispatch = useDispatch();
  const { subscribePacket } = useAppSelector((state) => state.payment);
  const size: Size = useWindowSize();
  const width = size.width !== undefined && size.width >= 425 ? "48%" : "100%";
  const buttonWidth =
    size.width !== undefined && size.width >= 425 ? "30%" : "100%";

  const formik: FormikProps<formValuesOffer> = useFormik<formValuesOffer>({
    initialValues: {
      categoryName: "",
    },
    onSubmit: (values) => {
      console.log("");
    },
    validate: (values) => {
      let errors: formValuesOffer = {
        categoryName: "",
      };
      if (!values.categoryName) {
        errors.categoryName = "Required!";
      }
    },
  });
  return (
    <Container>
      <HeaderContainer borderColor={subscribePacket.color}>
        <Header>{subscribePacket.label}</Header>
      </HeaderContainer>
      <FormContainer>
        <FormTitle>Teklif Al</FormTitle>
        <FormSubmitParagraph>
          Devam’a tıklayarak Kullanım Koşullarını ve Gizlilik Politikasını kabul
          etmiş olursunuz. Ayrıca 16 yaşından büyük olduğunuzu kabul
          ediyorsunuz.
        </FormSubmitParagraph>
        <form onSubmit={formik.handleSubmit}>
          <LabelwithInput
            value={formik.values.categoryName}
            handleChange={formik.handleChange}
            name="categoryName"
            type="text"
            placeholder="Kategori İsmi"
            style={{
              width: width,
              marginBottom: 32,
              borderColor: "red",
              marginTop: 32,
            }}
            errors={formik.errors.categoryName ? true : false}
          />
          <FormSubmitContainer>
            <Button
              type="submit"
              bgColor="#000"
              style={{
                color: "white",
                maxWidth: buttonWidth,
              }}
              onClick={() => {
                setOpen(false);
                dispatch(setOfferCardInfo(formik.values));
              }}
            >
              Devam Et
            </Button>
            <FormSubmitParagraph>
              Devam’a tıklayarak Kullanım Koşullarını ve Gizlilik Politikasını
              kabul etmiş olursunuz. Ayrıca 16 yaşından büyük olduğunuzu kabul
              ediyorsunuz.
            </FormSubmitParagraph>
          </FormSubmitContainer>
        </form>
      </FormContainer>
    </Container>
  );
};

export default OfferCard;
