import styled from "styled-components";

export const Wrapper = styled.div<{ width: number }>`
  display: flex;
  flex-direction: column;

  /* border: 1px solid green; */
`;
export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 425px) {
    flex-direction: column-reverse;
    gap: 16px;
  }
`;
export const CardInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 425px) {
    flex-direction: column;
  }
`;

export const InfoText = styled.h3`
  font-size: 12px;
  font-weight: normal;
  margin-right: 12px;
  @media screen and (max-width: 425px) {
    font-size: 12px;
    margin-right: 0px;
  }
`;

export const Title = styled.h1<{ h: number, showMoreTitle: boolean }>`
  width: 100%;
  font-size: ${(props) => props.h}rem;
  font-weight: bold;
  padding: 8px 0px;
  border-bottom: 2px solid black;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  ${(props) => props.showMoreTitle && "-webkit-line-clamp: 10;"}
  
  @media screen and (max-width: 425px) {
    font-size: 22px;
    border-bottom: 0px;
    padding: 0px;
  }
`;

export const SubTitle = styled.h2<{ w: number }>`
  font-size: 12px;
  padding: 6px 0px;
  font-weight: normal;
  border-bottom: 1px solid #c2c2c2;
  color: #c2c2c2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  cursor: pointer;

  @media screen and (max-width: 425px) {
    display: none;
  }

  &:hover {
    color: #707070;
  }
`;
