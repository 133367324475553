import { Wrapper, Title, GreenDot, LineItem, LineText, RedDot } from "./styles";
import { useTranslation } from 'react-i18next';

const LiveVotes = () => {
  const {t} = useTranslation(['home']);
  return (
    <Wrapper>
      <Title>
        <GreenDot style={{ borderWidth: "4px" }} />
        {t('live-choices')}
      </Title>
      <LineItem>
        <LineText>TR MAR</LineText>
        <LineText>d56195ab-************-adb2ea3554ea</LineText>
        <GreenDot />
      </LineItem>
      <LineItem>
        <LineText>TR BUR</LineText>
        <LineText>d56195ab-************-adb2ea3554ea</LineText>
        <RedDot />
      </LineItem>
      <LineItem>
        <LineText>TR ANT</LineText>
        <LineText>d56195ab-************-adb2ea3554ea</LineText>
        <GreenDot />
      </LineItem>
      <LineItem>
        <LineText>TR KOC</LineText>
        <LineText>d56195ab-************-adb2ea3554ea</LineText>
        <RedDot />
      </LineItem>
      <LineItem>
        <LineText>TR ANK</LineText>
        <LineText>d56195ab-************-adb2ea3554ea</LineText>
        <RedDot />
      </LineItem>
    </Wrapper>
  );
};

export default LiveVotes;
