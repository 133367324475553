import { useTranslation } from 'react-i18next';

export const getTitleSize = (width: number, length: number) => {
  let lengthFactor = 1;
  if (length <= 240 && length >= 40) lengthFactor = 1 - (0.5 / 200) * length;
  else if (length > 240) lengthFactor = 0.5;

  const fontSize = 1.5 + (width - 1024) * (1 / (width * 0.5));

  const result = fontSize * lengthFactor;

  if (result < 1.5) return 1.5;
  return result;
};

export const DateTime = (date: string) => {
  const CardYear = date?.slice(0, 4);
  const CardMonth = date?.slice(5, 7);
  const CardDay = date?.slice(8, 10);
  const CardTime = date?.slice(11, 16);

  const months = [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ];

  return `${CardDay} ${
    months[Number(CardMonth) - 1]
  } ${CardYear} - ${CardTime}`;
};

export const CardDuration = (date: string) => {
  const { t } = useTranslation(['home']);
  const CardYear = date?.slice(0, 4);
  const CardMonth = date?.slice(5, 7);
  const CardDay = date?.slice(8, 10);
  const CardTime = date?.slice(11, 16);

  const startTime = new Date(`${CardYear}/${CardMonth}/${CardDay} ${CardTime}`);
  const endTime = new Date();
  const difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
  const resultInMinutes = Math.round(difference / 60000);

  const CardDayDiff = Math.floor(resultInMinutes / (60 * 24));
  const CardHourDiff = Math.floor((resultInMinutes % (60 * 24)) / 60);
  const CardMinDiff = Math.floor((resultInMinutes % (60 * 24)) % 60);

  if (CardDayDiff)
    return `${CardDayDiff + t('day')} ${CardHourDiff + t('hour')} ${CardMinDiff + t('minute')}`;
  if (CardHourDiff) return `${CardHourDiff + t('hour')} ${CardMinDiff + t('minute')}`;
  return `${CardMinDiff + t('minute')}`;
};

