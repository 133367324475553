import React from "react";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Wrapper } from "./styles";
const arr = [
  " SVAYP is a 100% independent platform. There's no enforced registration. You are completely anonymous.",
  " If you choose to register, your personal information* will never be shared with other individuals or institutions. *phone number, email, and identification information.",
  " The only data that the system automatically collects is the country information where you downloaded SVAYP.",
  " What makes SVAYP valuable are the demographic data you share, the type of votes you cast, and the vote percentages.",
  " SVAYP was designed with the idea of being a platform through which we can convey as a society what we prefer, what we show interest in, and how we react to various matters. It allows you to freely express your opinions on any topic.",
];
const TextSlider = () => {
  const settings: Settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <div />,
    prevArrow: <div />,
  };
  return (
    <Wrapper>
      <Slider {...settings}>
        {arr.map((item) => (
          <div>{item}</div>
        ))}
      </Slider>
    </Wrapper>
  );
};

export default TextSlider;
