import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';


import navTR from './navbar/tr.json';
import navSG from './navbar/sg.json';
import navEN from './navbar/en.json';
import navFR from './navbar/fr.json';

import homeTR from './home/tr.json';
import homeSG from './home/sg.json';
import homeEN from './home/en.json';
import homeFR from './home/fr.json';

import footerTR from './footer/tr.json';
import footerSG from './footer/sg.json';
import footerEN from './footer/en.json';
import footerFR from './footer/fr.json';

import subscribeTR from './subscribe/tr.json';
import subscribeSG from './subscribe/sg.json';
import subscribeEN from './subscribe/en.json';
import subscribeFR from './subscribe/fr.json';

import privacyPolicyTR from './privacy-policy/tr.json';
import privacyPolicySG from './privacy-policy/sg.json';
import privacyPolicyEN from './privacy-policy/en.json';
import privacyPolicyFR from './privacy-policy/en.json';

import userAgreementTR from './user-agreement/tr.json';
import userAgreementSG from './user-agreement/sg.json';
import userAgreementEN from './user-agreement/en.json';
import userAgreementFR from './user-agreement/fr.json';


const resources = {
    tr: {
        navbar: navTR,
        home: homeTR,
        footer: footerTR,
        subscribe: subscribeTR,
        privacyPolicy: privacyPolicyTR,
        userAgreement: userAgreementTR,
    },
    sg: {
        navbar: navSG,
        home: homeSG,
        footer: footerSG,
        subscribe: subscribeSG,
        privacyPolicy: privacyPolicySG,
        userAgreement: userAgreementSG,
    },
    en: {
        navbar: navEN,
        home: homeEN,
        footer: footerEN,
        subscribe: subscribeEN,
        privacyPolicy: privacyPolicyEN,
        userAgreement: userAgreementEN,
    },
    us: {
        navbar: navEN,
        home: homeEN,
        footer: footerEN,
        subscribe: subscribeEN,
        privacyPolicy: privacyPolicyEN,
        userAgreement: userAgreementEN,
    },
    fr: {
        navbar: navFR,
        home: homeFR,
        footer: footerFR,
        subscribe: subscribeFR,
        privacyPolicy: privacyPolicyFR,
        userAgreement: userAgreementFR,
    },
}

const userLanguage = navigator.language.split('-')[0];
const languageToLoad = userLanguage; 

i18next
    .use(initReactI18next)
    .init({
        resources,
        lng: languageToLoad,
        debug: false,
        fallbackLng: 'tr',
        saveMissing: true
});

export default i18next;