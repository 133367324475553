import React from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import {
  Image,
  ImageWrpper,
  Slogan,
  SloganWrapper,
  SubscribeWrapper,
} from "./styles";
import SvaypLogo from "../../assets/subscribe/whiteSvaypLogo.svg";

const SubscribeHeader = () => {
  const { t } = useTranslation(['subscribe']);
  return (
    <SubscribeWrapper>
      <ImageWrpper>
        <Link to={"/"}>
          <Image>
            <img src={SvaypLogo} alt="Svayp" height={41} width={164} />
          </Image>
        </Link>
      </ImageWrpper>
      <SloganWrapper>
        <Slogan>{t('title')}</Slogan>
      </SloganWrapper>
    </SubscribeWrapper>
  );
};

export default SubscribeHeader;
