import { useEffect, useState } from "react";
import SubscribeHeader from "../../components/subscribeHeader";
import { setShowHeader } from "../../redux/features/subscribeSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import AccountCard from "./components/AccountCard";
import OfferCard from "./components/OfferCard";
import OrderCard from "./components/OrderCard";
import OrderSummaryCard from "./components/OrderSummaryCard";
import {
  CardContainer,
  CloseBox,
  CloseHeader,
  Description,
  DescriptionWrapper,
  PaymentWrapper,
  WarningText,
  Wrapper,
} from "./styles";

const Payment = () => {
  const { orderCardInfo, subscribePacket } = useAppSelector(
    (state) => state.payment
  );
  const [accountCardOpen, setAccountCard] = useState(true);
  const [orderCardOpen, setOrderCard] = useState(false);
  const [offerCardOpen, setOfferCard] = useState(false);
  const [previewCardOpen, setPreviewCard] = useState(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setShowHeader(false));
  }, [dispatch]);
  return (
    <Wrapper>
      <PaymentWrapper>
        <SubscribeHeader />
        <DescriptionWrapper>
          <Description>
            Kayıt olmayı tercih etsen de sana özel bilgilerin* asla başkalarıyla
            paylaşılmaz.
          </Description>
          <WarningText>*telefon numaran ve kimlik bilgin.</WarningText>
        </DescriptionWrapper>
        <CardContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {accountCardOpen ? (
              <AccountCard
                setOpen={setAccountCard}
                setOtherCard={
                  subscribePacket.label === "Bireysel"
                    ? setOrderCard
                    : setOfferCard
                }
              />
            ) : (
              <CloseBox>
                <CloseHeader>Hesap Detayları</CloseHeader>
              </CloseBox>
            )}
            {subscribePacket.label === "Bireysel" ? (
              orderCardOpen ? (
                <OrderCard
                  setOpen={setOrderCard}
                  setOtherCard={setPreviewCard}
                />
              ) : (
                <CloseBox
                  onClick={() =>
                    orderCardInfo.cvv !== "" && setOrderCard(!orderCardOpen)
                  }
                >
                  <CloseHeader>Ödeme</CloseHeader>
                </CloseBox>
              )
            ) : offerCardOpen ? (
              <OfferCard setOpen={setOfferCard} setOtherCard={setPreviewCard} />
            ) : (
              <CloseBox
                onClick={() =>
                  orderCardInfo.cvv !== "" && setOrderCard(!orderCardOpen)
                }
              >
                <CloseHeader>Teklif Al</CloseHeader>
              </CloseBox>
            )}

            {!previewCardOpen && (
              <CloseBox onClick={() => setPreviewCard(true)}>
                <CloseHeader>Gözden Geçir ve Onayla</CloseHeader>
              </CloseBox>
            )}
          </div>
          {subscribePacket.label === "Bireysel" && <OrderSummaryCard />}
        </CardContainer>
      </PaymentWrapper>
    </Wrapper>
  );
};

export default Payment;

/*



*/
