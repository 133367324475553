import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  FilterResponse,
  RequestFilter,
  RequestFilterItem,
} from "../../types/general";
import { HttpRequest, RequestType } from "../../plugins/httpRequest";
import { Card } from "../../types/card";

export const getCards = createAsyncThunk(
  "cards",
  async ({
    skip,
    countryId,
    categoryId,
  }: {
    skip: number;
    countryId: string;
    categoryId?: string;
  }) => {
    const items: RequestFilterItem[] = [
      {
        Name: "releaseType",
        Values: ["BOTH", "WEB"],
        Type: "in",
      },
      {
        Name: "countryId",
        Value: countryId,
        Type: "=",
      },
    ];
    if (categoryId) {
      items.push({
        Name: "categoryId",
        Value: categoryId,
        Type: "=",
      });
    }
    const body: RequestFilter = {
      Type: "and",
      Items: items,
    };

    const response = await HttpRequest<any, FilterResponse<Card>>({
      method: RequestType.POST,
      url: `ClientFilter/Cards/All`,
      body,
      params: {
        skip,
        take: 4,
        orderBy: "createdAt desc",
      },
    });
    return response;
  }
);

export const getScrollCards = createAsyncThunk(
  "scrollCards",
  async ({ countryId }: { countryId: string }) => {
    const response = await HttpRequest<any, any>({
      method: RequestType.GET,
      url: `ClientFilter/Cards/All`,
      params: {
        skip: 0,
        take: 50,
        orderBy: "createdAt desc",
      },
    });
    return response;
  }
);
