import React from "react";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SilderWrapper } from "./styles";

const arr = [
  " SVAYP %100 bağımsız bir platformdur. Kayıt şartı yok. Tamamen anonimsin.",
  " Kayıt olmayı tercih ettiğinde sana özel bilgilerin* asla başka kişi ve kurumlarla paylaşılmaz. *telefon numaran, e-posta ve kimlik bilgin.",
  " Sistemin otomatik olarak görebildiği tek veri SVAYP’ı indirdiğin ülke bilgisidir.",
  " SVAYP’ı değerli kılan paylaştığın demografik bilgiler, oy türü ve oy oranlarıdır.",
  " SVAYP toplum olarak neyi tercih ettiğimizi, neye ilgi ve tepki gösterdiğimizi ilgili yerlere ileteceğimiz bir platform olması düşüncesi ile tasarlandı, her konu hakkında özgürce kanaatini belirtebilmeni sağlar."
];
const TextSlider = () => {
  const settings: Settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    pauseOnHover: true,
    pauseOnDotsHover: true,
  };
  return (
    <SilderWrapper>
      <Slider {...settings}>
        {arr.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
      </Slider>
    </SilderWrapper>
  );
};

export default TextSlider;
