import { createSlice } from "@reduxjs/toolkit";

export interface SubscribeState {
  isShowHeader: boolean;
}

const initialState: SubscribeState = {
  isShowHeader: true,
};

export const subscribeSlice = createSlice({
  name: "subscribe",
  initialState,
  reducers: {
    setShowHeader: (state, action) => {
      state.isShowHeader = action.payload;
    },
  },
});

export const { setShowHeader } = subscribeSlice.actions;

export default subscribeSlice.reducer;
