import styled from "styled-components";

export const Wrapper = styled("div")`
  margin-top: 5rem;
  margin-bottom: 5rem;
  .slick-dots {
    position: absolute;
    bottom: -50px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
  }

  .slick-dots li {
    position: relative;

    display: inline-block;

    width: 10px;
    height: 10px;
    margin: 0;
    padding: 0;

    cursor: pointer;
  }
  .slick-dots li button {
    width: 100%;
    height: 100%;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
  }
  /*Slider*/

  .slick-slide > div {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    text-align: center;
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 500;
    @media screen and (min-width: 1024px) and (max-width: 1440px) {
      width: 80%;
    }
    @media screen and (max-width: 425px) {
      width: 85%;
    }
  }
`;

/* Dots *
  .slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 45px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}*/
