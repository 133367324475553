import styled from "styled-components";

export const Container = styled.div`
  width: 50%;
  border-radius: 12px;
  background-color: #fff;
  margin-bottom: 60px;
  padding-top: 32px;
  padding-bottom: 32px;
  position: relative;
  max-height: 519px;
  // @media screen and (max-width: 1439px) and (min-width: 1024px) {
  //   width: 50%;
  // }
  @media screen and (max-width: 425px) {
    width: 92%;
  }
`;
export const Line = styled.div<{
  color: any;
}>`
  border-bottom: 1px solid ${(props) => `${props.color}`};
  margin-bottom: 24px;
  margin-top: 24px;
`;
export const HeaderContainer = styled.div<{
  borderColor: any;
}>`
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 32px;
  margin-right: 32px;
  border-bottom: 1px solid ${(props) => `${props.borderColor}`};
`;

export const Header = styled.h3`
  font-family: Poppins;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
`;

export const FormContainer = styled.div`
  padding-top: 24px;
  padding-bottom: 32px;
  padding-left: 31px;
  padding-right: 31px;
`;
export const FormRowContainer = styled.div<{
  bottom?: any;
}>`
  display: flex;
  fledirection: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${(props) => `${props.bottom ? props.bottom : "0"}`}px;
`;
export const FormTitle = styled.h3`
  font-family: Poppins;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
`;
export const FormTitle2 = styled.h6`
  font-family: Poppins;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #5a5a5a;
`;
export const FormTitle3 = styled.h5`
  font-family: Poppins;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000;
`;
export const FormTitle4 = styled.h1`
  font-family: Poppins;
  font-weight: 500;
  font-size: 42px;
  line-height: 52px;
  color: #5a5a5a;
`;
