import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  max-width: 100%;
  padding: 32px 112px 56px;
  background: #f3f3f3;
  @media screen and (max-width: 425px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 34px 32px 34px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
`;

export const TrademarkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

export const TrademarkText = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #5a5a5a;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 128px;
  padding-top: 32px;
`;

export const NavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
  height: 100px;
`;

export const NavItem = styled.nav`
  font-size: 14px;
  font-weight: 400;
  color: #5a5a5a;
  text-transform: uppercase;
  padding: 4px 0px;
  cursor: pointer;
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 300px;
`;
//MOBILE

export const MobileContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
`;
export const MobileTrademarkText = styled.p`
  font-size: 13px;
  font-weight: 400;
  color: #5a5a5a;
  width: 100%;
  text-align: center;
  margin-bottom: 32px;
  margin-top: 16px;
`;
export const MobileImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 24px;
  width: 100%;
`;
export const MobileNavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100px;
  margin-top: 32px;
  margin-bottom: 32px;
`;
export const MobileNavItem = styled.nav`
  font-size: 12px;
  line-height: 32px;
  font-weight: 400;
  color: #5a5a5a;
  padding: 4px 0px;
  cursor: pointer;
  text-transform: uppercase;
`;
