import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 12px;
  right: 12px;

  @media (max-width: 1439px) {
    display: none;
  }
`;

export const Title = styled.h3`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  color: #5a5a5a;
  border-bottom: 1px solid #5a5a5a;
  align-items: center;
  gap: 4px;
`;

export const GreenDot = styled.div`
  border-radius: 50%;
  border: 2px solid #34c759;
`;

export const RedDot = styled.div`
  border-radius: 50%;
  border: 2px solid #ed0505;
`;

export const LineItem = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #5a5a5a;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;
  gap: 24px;
`;

export const LineText = styled.p`
  font-size: 8px;
  color: #5a5a5a;
`;
