import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
export const SubscribeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding-top: 47px;
  background: #000;
  @media screen and (max-width: 425px) {
    height: 100%;
    max-height: 100%;
    width: 100%;
  }
`;
export const ImageWrpper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-bottom: 8px;
  border-bottom: 1px solid #fff;
`;
export const Image = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 100%;
`;

export const SloganWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 8px 0px 8px 0px;
  border-bottom: 2px solid #fff;
`;
export const Slogan = styled.h1`
  font-size: 30px;
  font-weight: 400;
  line-height: 22px;
  color: #fff;
  text-align: center;
  @media screen and (max-width: 425px) {
    font-size: 18px;
    font-weight: 400;
    line-height: 16px;
  }
`;
export const SubscribeCardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 120px 0px 120px 0px;
  gap: 32px;
  @media screen and (min-width: 1024px) and (max-width: 1440px) {
    gap: 20px;
    flex-wrap: nowrap;
  }
  @media screen and (max-width: 425px) {
    padding: 30px 0px 30px 0px;
  }
`;
