import styled from "styled-components";

//? Arrow Animation (Left Right Top)

//*  Usage: {keyframes} import
//*  animation: ${growFromRight} 0.8s ease-out;
//*  animation-fill-mode: forwards;

// const growFromLeft = keyframes`
//   0% {
//     width: 0;
//     opacity: 0;
//     transform: translateX(-50%);
//   }
//   100% {
//     width: auto;
//     opacity: 1;
//     transform: translateX(0);
//   }
// `;

// const growFromRight = keyframes`
//   0% {
//     width: 0;
//     opacity: 0;
//     transform: translateX(+50%);
//   }
//   100% {
//     width: auto;
//     opacity: 1;
//     transform: translateX(0);
//   }
// `;

// const growFromTop = keyframes`
//   0% {
//     width: 0;
//     opacity: 0;
//     transform: translateY(+50%);
//   }
//   100% {
//     width: auto;
//     opacity: 1;
//     transform: translateY(0);
//   }
// `;

export const Wrapper = styled.div<{ width?: number }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 5rem;
  padding-right: 128px;

  @media (max-width: 1439px) {
    padding-right: 0px;
  }

  @media screen and (max-width: 425px) {
    /* height: auto;
    padding-bottom: 0px;
    padding-right: 0px;
    padding-top: 16px;
    justify-content: flex-start;
    align-items: center;
    min-height: 285px; */
    padding-top: 1rem;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RatesWrapper = styled.div<{ size?: number }>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* min-height: 11rem; */

  // border: 1px solid orange;
  @media screen and (max-width: 425px) {
    min-height: auto;
  }
  /* border: 1px solid red; */
`;

export const GreenArrowImage = styled.img<{ h: number; kat: number }>`
  height: ${(props) => ((props.h - 50) * 0.05 + 7.5) * props.kat}rem;
  max-height: 20rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.8s ease;
  @media screen and (max-width: 425px) {
    height: ${(props) => ((props.h - 50) * 0.05 + 7.5) * props.kat}rem;
  }
`;

export const RedArrowImage = styled.img<{ h: number; kat: number }>`
  height: ${(props) => (7.5 - (50 - props.h) * 0.05) * props.kat}rem;
  /* min-height: 5.4rem; */
  max-height: 20rem;
  position: absolute;
  bottom: 0;
  right: 0;
  transition: all 0.8s ease;
  /* border: 1px solid blue; */
  @media screen and (max-width: 425px) {
    height: ${(props) => (7.5 - (50 - props.h) * 0.05) * props.kat}rem;
  }
`;

export const ImageWrapper = styled.div<{ w: number; h: number; kat: number }>`
  position: relative;
  height: ${(props) => props.h * props.kat}rem;
  width: ${(props) => props.w * props.kat}rem;
  /* border: 1px solid red; */
  @media screen and (max-width: 425px) {
    display: flex;
    height: ${(props) => props.h * props.kat}rem;
    width: ${(props) => props.w * props.kat}rem;
  }
`;

export const GreenDiv = styled.div<{ h: number; kat: number }>`
  height: ${(props) => ((props.h - 50) * 0.05 + 7.5) * props.kat}rem;
  /* max-height: 10rem; */
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  right: calc(50% + ${(props) => props.kat * 5}rem);

  @media screen and (max-width: 425px) {
    height: ${(props) => ((props.h - 50) * 0.05 + 7.5) / 2}rem;
    min-height: 5rem;
    max-height: 6rem;
    right: calc(50% + 4rem);
    min-width: 90px;
  }

  /* border: 1px solid green; */
`;

export const GreenRateWhole = styled.div<{ h: number; kat: number }>`
  display: flex;
  align-items: end;
  font-size: ${(props) => (1 + props.h * 0.02) * props.kat}rem;
  line-height: ${(props) => (1 + props.h * 0.02) * props.kat}rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
`;

export const GreenRateState = styled.p<{ h: number; kat: number }>`
  font-size: ${(props) => ((1.25 + props.h * 0.03) / 2) * props.kat}rem;
  font-weight: medium;
  text-align: right;
  white-space: nowrap;

  /* border: 1px solid green; */
`;

export const RedDiv = styled.div<{ h: number; kat: number }>`
  height: ${(props) => (7.5 - (50 - props.h) * 0.05) * props.kat}rem;
  /* min-height: 5rem; */
  /* max-height: 10rem; */
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  left: calc(50% + ${(props) => props.kat * 5}rem);

  @media screen and (max-width: 425px) {
    height: ${(props) => ((props.h - 50) * 0.05 + 7.5) / 2}rem;
    min-height: 5rem;
    max-height: 6rem;
    left: calc(50% + 4rem);
    min-width: 90px;
  }

  /* border: 1px solid red; */
`;

export const RedRateWhole = styled.div<{ h: number; kat: number }>`
  display: flex;
  align-items: end;
  font-size: ${(props) => (1 + props.h * 0.02) * props.kat}rem;
  line-height: ${(props) => (1 + props.h * 0.02) * props.kat}rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
`;

export const RedRateState = styled.p<{ h: number; kat: number }>`
  font-size: ${(props) => ((1.25 + props.h * 0.03) / 2) * props.kat}rem;
  font-weight: medium;
  text-align: left;
  white-space: nowrap;
`;

export const GrayWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const GrayContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: -1.5rem;

  /* border: 1px solid orange; */
`;

export const GrayArrowImage = styled.img<{ h: number; kat: number }>`
  height: ${(props) => ((props.h - 50) * 0.05 + 7.5) * props.kat}rem;
  transition: all 0.8s ease;
`;

// export const GrayArrowImage = styled.img<{ h: number; kat: number }>`
//   height: ${(props) => (7.5 - (50 - props.h) * 0.05) * props.kat}rem;
//   /* min-height: 5.4rem; */
//   max-height: 20rem;
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   /* border: 1px solid blue; */
//   @media screen and (max-width: 425px) {
//     height: ${(props) => props.h * 0.5 * props.kat}rem;
//   }
// `;

export const VoteCount = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
`;
