import { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { SubscribeCardsWrapper, SubscribeWrapper, Wrapper } from "./styles";
import { useAppDispatch } from "../../redux/store";
import { setShowHeader } from "../../redux/features/subscribeSlice";
import SubscribeCards from "./components/subscribeCards";
import TextSlider from "./components/textSlider/index";
import SubscribeHeader from "../../components/subscribeHeader";

const Subscribe = () => {
  const { t } = useTranslation(['subscribe']);
  const subscribeCards = [
    {
      borderColor: "#34C759",
      title: t('individual'),
      price: "$9.90",
      list: [
       t('individual-description.feature-1'),
       t('individual-description.feature-2'),
       t('individual-description.feature-3'),
       t('individual-description.feature-4'),
      ],
    },
    {
      borderColor: "#FFC303",
      title: t('category'),
      price: t('get-offer'),
      list: [
        t('category-description.feature-1'),
        t('category-description.feature-2'),
        t('category-description.feature-3'),
        t('category-description.feature-4'),
      ],
    },
    {
      borderColor: "#0076FF",
      title: t('public-opinion'),
      price: t('get-offer'),
      list: [
        t('public-opinion-description.feature-1'),
        t('public-opinion-description.feature-2'),
        t('public-opinion-description.feature-3'),
      ],
    },
    {
      borderColor: "#9CF4DC",
      title: t('academic'),
      price: t('contact-us'),
      list: [
        t('academic-description.feature-1'),
        t('academic-description.feature-2'),
        t('academic-description.feature-3'),
      ],
    },
  ];
  
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setShowHeader(false));
  }, [ dispatch ]);
  return (
    <div>
      <head>
        <title>Subscribe Svayp</title>
      </head>
      <Wrapper>
        <SubscribeWrapper>
          <SubscribeHeader />
          <SubscribeCardsWrapper>
            {subscribeCards.map((card: any) => (
              <SubscribeCards key={card} card={card} />
            ))}
          </SubscribeCardsWrapper>
        </SubscribeWrapper>
        <TextSlider />
      </Wrapper>
    </div>
  );
};

export default Subscribe;
