import { FC } from "react";
import { useTranslation } from 'react-i18next';
import { CardDuration } from "../../../../utils/functions";
import { CardShare, CardTime, Wrapper } from "./styles";
import SendIcon from "../../../../assets/home/sendIcon.svg";

interface SocialShareProps {
  createdAt: string;
}

const SocialShare: FC<SocialShareProps> = ({ createdAt }) => {
  const { t } = useTranslation(['home']);
  return (
    <Wrapper>
      <CardTime>{CardDuration(createdAt)}</CardTime>
      <CardShare>
        <img
          src={SendIcon}
          alt="send-icon"
          width={24}
          height={24}
          style={{
            position: "absolute",
            left: "12px",
            top: "6px",
          }}
        />
        {t('share')}
      </CardShare>
    </Wrapper>
  );
};

export default SocialShare;
