import React from "react";
import { useAppSelector } from "../../../../redux/store";
import { BottomLine, BottomLineWrapper } from "../AccountCard/style";
import {
  Container,
  FormContainer,
  FormRowContainer,
  FormTitle2,
  FormTitle3,
  FormTitle4,
  Header,
  HeaderContainer,
  Line,
} from "./styles";
const OrderSummaryCard = ({
  setOpen,
  open,
}: {
  setOpen?: any;
  open?: boolean;
}) => {
  const { subscribePacket } = useAppSelector((state) => state.payment);
  return (
    <Container onClick={() => setOpen(!open)}>
      <HeaderContainer borderColor={subscribePacket.color}>
        <Header>Sipariş Özeti</Header>
      </HeaderContainer>
      <FormContainer>
        <FormRowContainer bottom={24}>
          <FormTitle4>{subscribePacket.price}</FormTitle4>
          <FormTitle2>Aylık/</FormTitle2>
        </FormRowContainer>
        <FormRowContainer bottom={16}>
          <FormTitle2>Plan</FormTitle2>
          <FormTitle2>Price</FormTitle2>
        </FormRowContainer>
        <FormRowContainer bottom={16}>
          <FormTitle2>Bireysel Oylama Yapabilmek</FormTitle2>
          <FormTitle2>{subscribePacket.price}</FormTitle2>
        </FormRowContainer>
        <Line color={"#000"} />
        <FormRowContainer>
          <FormTitle2>Ara Toplam</FormTitle2>
          <FormTitle2>{subscribePacket.price}</FormTitle2>
        </FormRowContainer>
        <Line color={"#000"} />
        <FormRowContainer bottom={16}>
          <FormTitle3>Toplam Ödenecek Tutar</FormTitle3>
          <FormTitle3>{subscribePacket.price}</FormTitle3>
        </FormRowContainer>
        <FormTitle2>*Yinelenen bir aylık ücrettir.</FormTitle2>
      </FormContainer>
      <BottomLineWrapper>
        <BottomLine color={subscribePacket.color} />
      </BottomLineWrapper>
    </Container>
  );
};

export default OrderSummaryCard;
