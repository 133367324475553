import Select, { components, StylesConfig } from "react-select";
import dropdownIcon from "../../assets/payment/dropdownIcon.svg";
import { MyOptionType } from "../../mock/select-options-data";

type IsMulti = false;
interface ISelectBox {
  placeholder: string;
  options: MyOptionType[];
  value: any;
  handleChange: any;
  errors?: boolean;
  style?: any;
}
const SelectBox = ({
  placeholder,
  value,
  options,
  handleChange,
  errors,
  style,
}: ISelectBox) => {
  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <img style={{cursor: "pointer"}} src={dropdownIcon} alt="dropdownIcon" height={32} width={32} />
      </components.DropdownIndicator>
    );
  };
  const selectStyle: StylesConfig<MyOptionType, IsMulti> = {
    option: (provided, state) => ({
      color: state.isSelected ? "#000" : "#5A5A5A",
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: 14,
      paddingTop: 14,
      paddingBottom: 14,
      paddingLeft: 16,
      paddingRight: 16,
      cursor: "pointer",
      transition: "all 250ms ease",
      backgroundColor: state.isSelected ? "#F2F2F2" : "#fff",

      ":hover": {
        backgroundColor: "#F2F2F2",
      },

    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: "100%",
      height: 54,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderRadius: 16,
      border: `1px solid ${errors ? "#ED0505" : "#000"}`,
      paddingTop: 14,
      paddingBottom: 14,
      paddingLeft: 16,
      paddingRight: 16,
    }),
    valueContainer: () => ({
      padding: 0,
      margin: 0,
      width: "100%",
      height: "auto",
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: 14,
      color: "#5A5A5A",
    }),
    placeholder: () => ({
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "14px",
      color: "#C2C2C2",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 8,
        ...style,
      }}
    >
      <label
        style={{
          fontFamily: "Poppins",
          fontWeight: "400",
          fontSize: 12,
          lineHeight: "18px",
          color: "#000",
        }}
      >
        {placeholder}
      </label>
      <Select
        styles={selectStyle}
        components={{
          DropdownIndicator,
          IndicatorSeparator: () => null,
          Input: () => null,
        }}
        placeholder="Seçiniz"
        options={options}
        onChange={handleChange}
        value={value.value}
      />
    </div>
  );
};

export default SelectBox;
