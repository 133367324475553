import { FC, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useWidth } from "../../../../hooks/useWidth";
import { setSkip } from "../../../../redux/features/cardsSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { DateTime, getTitleSize } from "../../../../utils/functions";
import { CardInfoWrapper, InfoText, SubTitle, Title, Wrapper } from "./styles";
import { Card } from "../../../../types/card";

interface ContentHeaderProps {
  cards: Card[];
}

const ContentHeader: FC<ContentHeaderProps> = ({ cards }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['home']);
  const [showMoreTitle, setShowMoreTitle] = useState(false);
  const width = useWidth();
  const { releaseStartDate, tags, source, title } = cards[0] || {
    releaseStartDate: "",
    tags: [],
    source: "",
    title: "No Card in Category",
  };
  const { skip } = useAppSelector((state) => state.cards);
  if (!cards) return;

  const handleCard = (index: number) => {
    dispatch(setSkip(skip + index));
  };


  return (
    width && (
      <Wrapper width={width}>
        <CardInfoWrapper>
          <InfoText style={{ whiteSpace: "nowrap" }}>
            {DateTime(releaseStartDate)}
          </InfoText>
          <InfoText
            style={{
              color: "#c2c2c2",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {t('tags')} {tags?.join(", ")}
          </InfoText>
          <InfoText style={{ color: "#c2c2c2" }}>
          {source && `${t('source')}: ${source.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n]+)/im)?.[1]}`}

          </InfoText>
        </CardInfoWrapper>

        <Title showMoreTitle={showMoreTitle} onClick={() => setShowMoreTitle(!showMoreTitle)} h={getTitleSize(width, title.length)}>{title}</Title>

       {cards[1] && 
       <SubTitle onClick={() => handleCard(1)} w={width}>
          {cards[1]?.title}
        </SubTitle>
        }
        {cards[2] && 
        <SubTitle onClick={() => handleCard(2)} w={width}>
          {cards[2]?.title}
        </SubTitle>
        }
        {cards[3] && 
        <SubTitle onClick={() => handleCard(3)} w={width}>
          {cards[3]?.title}
        </SubTitle>
        }
      </Wrapper>
    )
  );
};

export default ContentHeader;
