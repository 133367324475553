import styled from "styled-components";

export const Container = styled.div`
  width: 962px;
  border-radius: 12px;
  background-color: #fff;
  margin-bottom: 60px;
  padding-top: 32px;
  padding-bottom: 32px;
  position: relative;
  @media screen and (max-width: 425px) {
    width: 92%;
  }
`;

export const HeaderContainer = styled.div<{
  borderColor: any;
}>`
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 32px;
  margin-right: 32px;
  border-bottom: 1px solid ${(props) => `${props.borderColor}`};
`;

export const Header = styled.h3`
  font-family: Poppins;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
`;

export const FormContainer = styled.div`
  padding-top: 24px;
  padding-bottom: 32px;
  padding-left: 31px;
  padding-right: 31px;
`;

export const FormTitle = styled.h3`
  font-family: Poppins;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
`;
export const Form = styled.div`
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (max-width: 425px) {
    flex-wrap: no-wrap;
  }
`;

export const FormSubmitContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  @media screen and (max-width: 425px) {
    flex-direction: column-reverse;
    gap: 24px;
  }
`;

export const FormSubmitParagraph = styled.p`
  font-family: Poppins;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  @media screen and (max-width: 425px) {
    font-size: 11px;
    line-height: 16px;
  }
`;
