import { ContentStyle } from './style';

const Turkish = () => {
  return (
    <ContentStyle>
          <p><strong><em>SVAYP </em></strong>adıyla, yürürlükteki mevzuata uygun olarak varlığını sürdüren ve bu metin boyunca da<strong> “SVAYP<em>” </em></strong>olarak anılacak olan uygulama ve web sitesi, başta 6698 sayılı Kişisel Verilerin Korunmasına Dair Kanun (“KVKK”) olmak üzere, ilgili tüm hukuki normların gerekli kıldığı hak ve yükümlülüklere özen gösterir. İşbu metin de yasal zorunluluklara uygun olarak hazırlanmış olup, kullanıcıların aydınlatılması amacına özgülenmiş bulunmaktadır.</p>
          <p><strong><em>SVAYP</em></strong>’ın internet üzerindeki faaliyetleri temel olarak işbu aydınlatma metninin yayımlanmış olduğu web sitesi üzerinden (www.svayp.com) yürütülmekte olup; iş ve işlemlerin tümü, başta 5651 sayılı Kanun da dahil olmak üzere ilgili mevzuata uygun olarak yerine getirilmektedir.</p>
          <p>Yukarıda adı ve bilgileri verilen uygulama, 6698 sayılı Kişisel Verilerin Korunması Kanunu <em>(bu kısımdan sonra kısaca “KVKK” olarak anılacaktır) </em>doğrultusunda veri işlemektedir. İşlenen veriler bakımından ulaşılacak temsilcinin bilgileri aşağıdaki gibidir:</p>
          <br />
          <p><span><strong>Ad ve Soyad :</strong></span> Tarkan KAYA / SVAYP</p>
          <p><span><strong>E-posta :</strong></span> tarkan@svayp.com / tarkan.kaya@gmail.com</p>
          <br />
          <p>İşbu aydınlatma metni ve bilahare akdedilecek eki mahiyetindeki açık rıza metninde (kullanıcı sözleşmesi) yer alan temel veri işleme koşulları, 6698 sayılı KVKK ve Avrupa Birliği Resmi Gazetesinin L/119/1<span className="Apple-converted-space">&nbsp; </span>sayısında yayımlanan GVKT’ye (Genel Veri Koruma Tüzüğü – General Data Protection Regulation) dayanmaktadır. Ancak veri sorumlusunun sorumluluğu, KVKK’nin GVKT’ye uygun düştüğü hükümlerle sınırlıdır.</p>
          <br />
          <p>İşbu aydınlatma metninde, yasada aranan koşullara uygun olarak;</p>
          <ul>
            <li><strong><em>Veri sorumlusuna ait iletişim bilgileri, </em></strong></li>
            <li><strong><em>Veri sorumlusunun temel yasal/sözleşmesel sorumlulukları, </em></strong></li>
            <li><strong><em>Verilerin işlenme amacı, kimlere ve hangi saiklerle aktarılabileceği, </em></strong></li>
            <li><strong><em>Verilerin işlenmesi usulüne ilişkin yollar, </em></strong></li>
            <li><strong><em>Veri toplamanın şekli ve hukuki sebepleri</em></strong> bulunmaktadır.</li>
          </ul>
          <br />
          <p>İşbu aydınlatma metninin kişisel veri sahipleri tarafından okunup anlaşılması gerekmektedir. Metin <strong><em>SVAYP</em></strong>’ın karşılama sayfasına yerleştirilmiş olup, tam hali ile okunması için aynı sayfaya bir bağlantı (link) eklenmiştir. İşbu bağlantı linkinin bulunması, kullanıcılara aydınlatma metninin sunulmuş olduğu anlamını taşır. <span><strong>Veri sorumlusu işbu metni okunmaya hazır halde bulundurmak ve yasaya uygun şekilde veri sahiplerine sunmakla 6698 s. KVKK m. 10’da belirtilen aydınlatma yükümlülüğünü yerine getirmiş olmaktadır. </strong></span></p>
          <br />
          <p>Veri sorumlusunun veri işlemesi, şu aşamalardan sonra hukuka uygun ve geçerli hale gelmektedir:</p>
          <ul>
            <li>İşbu aydınlatma metninin veri sahipleri tarafından okunması ve veri sahiplerinin gereği gibi aydınlatılması,</li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>Verilerin işlenmesine ve işleme yöntemine ilişkin açık rızanın alınacağı, işbu aydınlatma metninin eki mahiyetindeki <strong>AÇIK RIZA METNİNİN</strong> onaylanması <em>(6698 s. KVKK’de sayılan açık rızanın gerekli olmadığı haller dışında).</em></li>
          </ul>
          <br />
          <p>Yukarıda sıralanan aşamalara gerek olmaksızın, yasada belirtilen hallerin varlığı halinde <strong>açık rızanın alınmasına gerek olmaksızın veri işlemenin mümkün olduğu haller</strong>, 6698 s. KVKK m. 5’te açıkça düzenlenmiş olup, önemle hatırlatılır:</p>
          <p><em>(a) Kanunlarda açıkça öngörülmesi </em></p>
          <p><em>(b) Fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda bulunan veya rızasına hukuki geçerlilik tanınmayan kişinin kendisinin ya da bir başkasının hayatı veya beden bütünlüğünün korunması için zorunlu olması.<br /> c) Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması.<br /> ç) Veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması.<br /> d) İlgili kişinin kendisi tarafından alenileştirilmiş olması.<br /> e) Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması.<br /> f) İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması.</em></p>
          <p>&nbsp;</p>
          <p>Bununla beraber, GVKT (GDPR) m. 6’da da açık rızanın aranmasına gerek olmaksızın veri işlenebilecek haller sıralanmıştır:</p>
          <p>&nbsp;</p>
          <p><em>(a) Veri sahibinin taraf olduğu bir sözleşmenin uygulanması veya bir sözleşme yapılmadan önce veri sahibinin talebiyle adımlar atılması için, işleme faaliyetinin gerekli olması; </em></p>
          <p><em>(b) Veri sorumlusunun tabi olduğu bir yasal yükümlülüğe uygunluk sağlanması amacı ile işleme faaliyetinin gerekli olması; </em></p>
          <p><em>(c) Veri sahibinin veya başka bir gerçek kişinin hayati menfaatlerinin korunması amacı ile işleme faaliyetinin gerekli olması; </em></p>
          <p><em>(d) Kamu yararına gerçekleştirilen bir görevin yerine getirilmesi veya kontrolöre verilen resmi bir yetkinin uygulanması hususunda işleme faaliyetinin gerekli olması; </em></p>
          <p><em>(e) Özellikle veri sahibinin çocuk olması halinde veri sahibinin kişisel verilerin korunmasını gerektiren menfaatleri veya temel hakları ve özgürlüklerinin bir kontrolör veya üçüncü bir kişi tarafından gözetilen meşru menfaatlere ağır basması haricinde, söz konusu menfaatler doğrultusunda işleme faaliyetinin gerekli olması. </em></p>
          <p>&nbsp;</p>
          <p>KVKK ya da GVKT’de sayılı hallerden birinin varlığı halinde, İLGİLİNİN (VERİ SAHİBİNİN) RIZASI BULUNMASA DAHİ KİŞİSEL VERİLERİN İŞLENMESİ MÜMKÜNDÜR.</p>
          <p>Yukarıda anılan rıza, <strong><em>“</em></strong><span className="s2"><strong><em>bilgilendirilmeye dayanan ve özgür iradeyle açıklanan onayı”</em></strong> ifade etmekte olup, konuya </span> ilişkin ayrıntılar, işbu metinde ve bilahare akdedilecek KULLANICI SÖZLEŞMESİNDE (AÇIK RIZA METNİNDE) belirtilmiştir.</p>
          <p><strong>Sözleşmeye ve işbu metne verilecek onaydan önce ifade edelim ki:</strong></p>
          <p>Verilerin işlenmesi ifadesinden anlaşılması gereken; verilerin kaydedilmesi, değiştirilmesi, elde edilmesi, aktarılması, yeniden düzenlenmesi, açıklanması, üçüncü kişilerle paylaşılması, depolanabilmesi işlemleri olup, bahse konu iş ve işlemlerin yürütülmesinden <strong><em>SVAYP</em></strong> sorumludur. Hangi işlemelere onay verileceği ve <strong><em>SVAYP</em></strong>’ın hangi işleme şekilleriyle veri işlediği aşağıda belirtilmiştir.</p>
          <p>&nbsp;</p>
          <p style={{textAlign: "center"}}><strong>A Ç I K L A M A L A R <span className="Apple-converted-space">&nbsp; &nbsp;</span></strong></p>
          <p>&nbsp;</p>
          <ul>
            <li><span><strong>VERİ İŞLEME POLİTİKASI</strong></span></li>
          </ul>
          <p>(1) Başta 6698 s. KVKK olmak üzere, yürürlükteki tüm hukuki düzenlemelere uygun hareket etmek suretiyle, kullanıcının kişisel verilerinin işlenmesine ilişkin yasal yükümlülüklere uyma mesuliyeti veri sorumlusuna aittir.</p>
          <p>(2) Kullanıcıların kişisel verilerine karşı yüksek düzeyde saygı duyulmaktadır; bu durum, kullanıcılarımızın ve bizimle kişisel verilerini paylaşan her kurum ve kişinin güvenini kazanmak konusunda gösterdiğimiz dikkati yansıtır. <strong>Kişisel</strong> <strong>verilerin işlenmesi, ancak </strong><span><strong>kanunda ya da ilgili hukuki normda aranan koşulların varlığı</strong></span><strong> halinde veya usulüne uygun olarak alınmış </strong><span><strong>açık rızanız</strong></span><strong> ile mümkündür. </strong>Öte yandan, yasada açık rıza olmaksızın veri işlenebildiği hallerde açık rızanız bulunmaksızın veri işlenmesi de mümkündür. İşlenen veriler; işbu aydınlatma metninde yazılı amaçlar doğrultusunda ve <span><strong>ölçülü olarak işlenir.</strong></span></p>
          <p>&nbsp;</p>
          <ul>
            <li><span><strong>vERİ İŞLEME YÖNTEMİ VE amacı</strong></span></li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li><strong><em>SVAYP,</em></strong> işbu aydınlatma metni ve gizlilik politikası ve akabinde akdedilecek kullanıcı sözleşmesindeki şartları kabul eden veri sahipleri için, uygulamaya kaydedilen verileri usulüne uygun olarak işleyebilir. İşleme; internet sitesi, mobil uygulama ve sosyal mecralardaki bilgi/talep/üyelik formları, aplikasyon adı verilen uygulamalarda veri sahipleri tarafından verilecek kişisel verilerin kaydı, depolanması ve aktarılması gibi şekillerle (aşağıda izah olunacağı üzere) elektronik ortamda gerçekleşmektedir. Bu bakımdan <strong><em>SVAYP,</em></strong> “veri sorumlusu” sıfatını taşır.</li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li><strong><em>SVAYP</em></strong>, web sitesinin ve uygulamalarının kullanımı için üyelik ya da veri paylaşımını zorunlu tutmaz. Ancak, <strong><em>SVAYP’a</em></strong> üye olarak kaydolunması durumunda, üye olmamış kullanıcılara göre daha işlevsel bir kullanım hizmeti sunulabilir.<span className="Apple-converted-space">&nbsp; </span><strong><em>SVAYP’a</em></strong> üye olan kullanıcılardan; ad ve soyadları, e-posta adresleri, telefon numaraları, yaşanılan yer gibi bilgileri istenir.</li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>Kişisel veriler; kullanıcıların açık rızalarının alınmasından sonra ölçülü olarak <strong><em>kaydedilebilecek, depolanabilecek ; mevzuatın izin verdiği ölçüde </em></strong><span><strong><em>üçüncü kişilere yurtiçinde ve yurtdışında veya buralara açıklanabilecek, devredilebilecek, aktarılabilecek, paylaşılabilecek,</em></strong></span> <span><strong><em>sınıflandırılabilecek, anonim hale getirilebilecek</em></strong></span> ve kanunda sayılan diğer şekillerde işlenebilecektir. Bununla beraber, web sitesinin kullanılabilmesi için <span><strong>zorunlu</strong></span> olan çerez kullanımları için açık rıza şartı aranmaz. Zorunlu çerezlerin kullanıldığı hususu <strong><em>SVAYP</em></strong>’ın karşılama sayfasında kullanıcılara bildirilir ve bu bildirim zorunlu çerezlerin kullanılması için yeterlidir. Ancak, web sitesinin daha iyi hizmet verebilmesi adına performans iyileştirici ya da reklam amaçlı çerezler için ayrıca açık onay aranır.</li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li><strong><em>SVAYP,</em></strong> sözü edilen kişisel verileri, kamuoyu araştırması (anket) yapmak, sonuçları bilimsel ve istatiksel veri haline getirerek kamuoyu ile paylaşmak ve bu sonuçları üçüncü kişilere aktarmak gibi amaçlar doğrultusunda işler. <strong><em>SVAYP</em></strong>, bilgi ve verileri yasal şartlara bağlı olarak toplayacak, kullanacak, belirli bir süre ile saklayacak, üçüncü kişilere aktaracak, anonimleştirerek anket haline getirecek ve yukarıda yazılı her şekilde işleyebilecektir. Bir diğer söyleyişle; <span><strong><em>SVAYP </em>üzerinden beyan edilecek kişisel görüş ve tercihler kullanıcıların ad ve soyadlarının gizli tutulması ve anonimleştirilmesi suretiyle bir anket haline getirilir; bu şekilde ticaret şirketlerinin ve sair üçüncü kişilerin bu verilere dayanarak stratejik planlama faaliyetlerinin yürütülmesine katkıda bulunulur.</strong></span> Oluşturulan anketler ve depolanan kişisel veriler, anonimleştirilerek üçüncü kişilere, topluluk şirketlerine ya da sair kimselere aktarılır.</li>
          </ul>
          <p>(5) <strong><em>SVAYP, </em></strong>işlediği verilerin saklanması ve bunlardan bir zarar doğmaması için gerekli tüm tedbirleri alır.</p>
          <ul>
            <li>Veri sahibi, site yazılımına veya siteye üye olmadan önce ya da sonra, sisteme yükleyeceği kişisel bilgi ve verilerini ALENİ hale getirerek herkesin erişimine açarsa, bahse konu veriler 6698 s. Kanun’un 5’inci maddesi doğrultusunda AÇIK RIZA ARANMAKSIZIN işlenebilir.</li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>Kullanıcıların 6698 s. KVKK doğrultusunda <strong>vazgeçme hakkı </strong>saklı tutulmuş olup, kaydedilen ve <span><strong>işlenen kişisel verilerin silinmesi talep edilebilir</strong></span>. Bunun için <strong><em>SVAYP’ın </em></strong>yukarıda verilen bilgileri kanalıyla tarafımızla iletişime geçilmesi ve yetkililerimizle görüşme sağlanması gerekir. &nbsp;Ancak, ilgili mevzuat hükümleri gereğince kişisel verileri muhafaza etme hak ve yükümlülüğü olan durumlarda kullanıcının işbu isteminin yerine getirilmemesi de mümkündür. Ancak bu haller yukarıda (sf.2) yazılı haller ile sınırlıdır.</li>
          </ul>
          <ul>
            <li><span><strong>VERİLERİN TOPLANMA BİÇİMİ VE çerez politikası</strong></span></li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>Öncelikle belirtelim ki, <strong><em>kişisel veri</em></strong> kavramı yalnızca kişilerin ad-soyadlarını, fotoğraflarını ve buna benzer somut bilgilerinin değil, kullandığı bilgisayarı, IP adresini, çerezlerini (cookies) ve buna benzer elektronik bilgilerini de içerir. Kişisel verilerin tarafımıza iletilmesi ve iletilen verilerin tarafımızca işlenmesi, temel olarak veri sahibinin açık rızasına ve aydınlatılmasına bağlıdır. Veri sahibi, işbu metnin kendisine sunulması ile aydınlatılmış olmaktadır.</li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>Yukarıda belirtildiği üzere, <strong><em>SVAYP </em></strong>kullanıldığında, veri sahipleri hakkında mevzuata uygun olarak veri toplanmaya başlanmaktadır. <span><strong>İlk işleme, zorunlu çerezlerin kullanıcıya sunumu ve kullanılması ile başlar.</strong></span> Bunun dışındaki veri işleme işlemleri, <span><strong>aydınlatma yükümünün tarafımızca yerine getirilmesinden</strong></span> ve açık rızanın alınmasından sonra işlenmektedir. Veri toplama süreci temel olarak aşağıdaki gibi yürümektedir. Ancak ekleyelim ki; <strong><em>SVAYP</em></strong>, kullanıcıların tarayıcıların çerez ayarlarını değiştirmemeleri (işlemeye müsait bırakmaları) durumunda, kullanıcılara daha etkili bir hizmet sunabilmek için otomatik olarak zorunlu çerez kullanmaya başlar; bu hususta kullanıcının rızası web sitesine girmekle alınmış sayılır. <span><strong>Bununla beraber, performans artırıcı ve reklam amaçlı çerezler için ayrıca onay alınması gerekir. Bu onay, web sitesinin karşılama sayfasındaki onay kutucuğunun işaretlenmesi ile alınır.</strong></span></li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>Çerezlere ilişkin veri işleme politikası ve işleme şekillerine ilişkin teknik hususlar 3 madde halinde ifade edilmiştir:</li>
          </ul>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <ul>
            <ul>
              <li><strong><em>Veri Sahiplerinin Web Tarayıcıları Tarafından SVAYP’a Gönderilen Veriler</em></strong><br /> <br /> <strong><em>SVAYP</em></strong>, web sitesine ya da aplikasyon adresine giriş yapılan web tarayıcısı tarafından tarafımıza otomatik olarak gönderilen bilgileri toplayabilir. Bu bilgiler veri sahibinin internet servis sunucusunun IP adresi, işletim sisteminin adı ve tipi ile, web tarayıcının versiyonunu ve sair teknik hususları içermektedir.</li>
            </ul>
          </ul>
          <p>&nbsp;</p>
          <p>Bu konu ile ilgili olarak, tarayıcının kullanıcı ile ilgili ne tür bilgiler gönderdiği, tamamıyla VERİ SAHİBİNİN sorumluluğu ve yetki alanında bulunan bir konudur. Kullanıcıların konuyla ilgili tarayıcı bilgi akışını durdurmak istemeleri durumunda, bununla ilgili tedbirler tarayıcı üzerinden ve yine kendileri tarafından alınır. Konuyla ilgili bir sorumluluk <strong><em>SVAYP</em></strong>’ın sorumluluğunda değildir.</p>
          <p>&nbsp;</p>
          <ul>
            <ul>
              <li><strong><em>Veri Sahibinin Elektronik Cihazına ve Tarayıcı ya da Tarayıcılarına Bir "Çerez (Cookie)" Yerleştirerek Edinilen Kişisel Veriler</em></strong><br /> <br /> <strong><em>SVAYP</em></strong>, kullanıcının bilgisayarının sabit diskine bir “etiket” yükleyerek hakkın bilgi toplayabilir. Bu etiket teknoloji dilinde “çerez” olarak bilinmektedir. <strong><em>SVAYP, </em></strong>bahse konu çerez ve sair teknik bilgileri, yukarıda yazılı mesleki ve ticari amaçlarına ulaşmak için kullanabilecek olup, anılan durum yukarıda belirtilen şartların yerine getirilmiş olmasına bağlıdır.</li>
            </ul>
          </ul>
          <p>&nbsp;</p>
          <p><strong><em>SVAYP’ın </em></strong>kullandığı çerezler aşağıdaki şekilde tablo haline getirilmiştir:</p>
          <p>&nbsp;</p>
          <table className="t1" style={{minWidth: '120px'}} cellSpacing={0} cellPadding={0}>
            <tbody>
              <tr>
                <td style={{minWidth: '120px'}} valign="middle">
                  <p style={{textAlign: 'center'}}><strong>Zorunlu Çerezler</strong></p>
                </td>
                <td style={{minWidth: '120px'}} valign="middle">
                  <p>Zorunlu çerezler, internet sitesinin çalışması ve amaçlandığı gibi kullanılabilmesi için kesinlikle gerekli olan çerezlerdir. Zorunlu çerezlerin engellenmesi halinde, internet sitesinin bazı bölümleri çalışmayacaktır. Bu çerezler, güvenliği iyileştirme, aramaları kaydetme, oturum açma veya benzer işlevlerin kullanılmasına imkân tanır ve pazarlama faaliyetleri amacıyla kullanılmaz. Kullanılması için açık rıza aranmaz.</p>
                </td>
              </tr>
              <tr>
                <td style={{minWidth: '120px'}} valign="middle">
                  <p style={{textAlign: 'center'}}><strong>İşlevsel Çerezler</strong></p>
                </td>
                <td style={{minWidth: '120px'}} valign="middle">
                  <p>İşlevsel çerezler, internet sitesini ziyaret eden kullanıcıların tercihlerine dayalı olarak site içeriğini onlar için <strong>bireyselleştirmeyi</strong> sağlar. Açık rıza ile kullanılır.</p>
                </td>
              </tr>
              <tr>
                <td style={{minWidth: '120px'}} valign="middle">
                  <p style={{textAlign: 'center'}}><strong>Performans Çerezleri</strong></p>
                </td>
                <td style={{minWidth: '120px'}} valign="middle">
                  <p>Performans çerezleri, web sitesinin geliştirilmesinde yardımcı olur. Kullanıcı deneyimini iyileştirmek amacıyla, sitenin ne şekilde ve ne kadar süre kullanıldığı gibi bilgileri toplar. Açık rıza ile kullanılır.</p>
                </td>
              </tr>
              <tr>
                <td style={{minWidth: '120px'}} valign="middle">
                  <p style={{textAlign: 'center'}}><strong>Reklam Çerezleri</strong></p>
                </td>
                <td style={{minWidth: '120px'}} valign="middle">
                  <p>Kullanıcıların ilgi alanlarına göre ve onlara uygun içerikler ile reklamları sunmak amacıyla kullanılır. Çerezler yoluyla elde edilen bilgileri, aynı kişiye ait diğer verilerle eşleştirerek, ilgililere daha uygun içerikleri, kişiye özel kampanya ve ürünleri sunar ve daha önceden istenmediği belirtilen içerik veya fırsatları bir daha sunmaz. Açık rıza ile kullanılır.</p>
                </td>
              </tr>
            </tbody>
          </table>
          <p><br /> Yukarıda sayılan çerezlerden ZORUNLU ÇEREZLERİN KULLANIMI İÇİN AÇIK RIZA ARANMAZ. Diğer çerezler için kullanıcının rızası aranır. Web sitesine giriş yapıldığı anda kullanıcılara, karşılama sayfasında zorunlu çerezlerin kullanıldığına ilişkin bir bildirim yapılır. Bu bildirimde yer alan onay butonuna tıklanması durumunda yukarıda sayılan diğer çerez kullanımlarına da onay verilir, bu çerezler bahse konu onaydan sonra kullanılmaya başlar.</p>
          <p>&nbsp;</p>
          <ul>
            <ul>
              <li><strong><em>Veri Sahiplerinin Bilerek ve İsteyerek Sağladığı Kişisel Veriler </em></strong><br /> <br /> <strong><em>SVAYP</em></strong>,<span className="Apple-converted-space">&nbsp; </span>bilerek ve isteyerek sağlanan veri ve bilgiler ile, yukarıda tanımlanan kişisel veri ve bilgileri toplamaktadır. Örnek olarak, veri sahiplerinin e-posta uyarıları almak için kaydolduğunda, bir formu doldurduğunda ya da tarafımıza geri bildirim için bir soru sorduklarında veya bir e-posta gönderdiklerinde sağladıkları veri ve bilgiler verilebilir.</li>
            </ul>
          </ul>
          <p>&nbsp;</p>
          <p>İşbu aydınlatma metni ve bilahare akdedilecek kullanıcı sözleşmesinin kabulü ile, verileri yukarıda belirtilen veri işleme amaçları doğrultusunda ve sözü edilen ticari amaçlar için kullanabileceğimiz hususu kabul edilmiş olur.</p>
          <p>&nbsp;</p>
          <ul>
            <li><span><strong>VERİ SAHİPLERİNİN (KULLANICILARIN) YASAL HAKLARI</strong></span></li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li><strong><em>SVAYP,</em></strong> verileri işbu aydınlatma metninde ve bilahare akdedilecek açık rıza akdinde (kullanıcı sözleşmesi) belirtilen hususlara uygun olarak işler. Ancak, daha önce de belirtildiği üzere, sözü edilen işlemeler, ancak veri sahibinin açık rızası ile mümkündür. Açık rızanın aranmadığı haller saklıdır.</li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>Veri sahibinin onayı, bahse konu işlemeleri cari kılar. Bunun yanında, veri sahibinin de aşağıda sıralanan şu hakları yasal ve akdi olarak teminat altına alınmıştır. Şöyle ki;</li>
          </ul>
          <p>&nbsp;</p>
          <p className="p14"><strong><em>Veri sahipleri</em></strong>;</p>
          <p>&nbsp;</p>
          <ul>
            <li>Kişisel verilerin işlenip işlenmediğini öğrenme,</li>
            <li>İşlenmişse buna ilişkin bilgi talep etme,</li>
            <li>Verilerin işlenme amacını ve bunların amaca uygun kullanılıp kullanılmadığını öğrenme,</li>
            <li>Verilerin aktarıldığı kişileri öğrenme,</li>
            <li>Verilerin eksik ya da yanlış işlenmesi durumunda bunların düzeltilmesini isteme,</li>
            <li><strong><em>Şartları oluştuğunda kişisel verilerin silinmesini veya yok edilmesini isteme</em></strong>, <em>(Verilerin işlenmesine ilişkin rızanın geri alınması, ileriye etkili olarak sonuç doğuracak olup; geri alma anına değin gerçekleştirilen işlemeler hukuka uygunluğunu sürdürecektir.)</em></li>
            <li>Düzeltme, silme ya da yok etme işlemlerinin verilerin aktarıldığı üçüncü kişilere iletilmesini isteme,</li>
            <li>İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,</li>
          </ul>
          <p>Haklarına sahiptir.</p>
          <p>&nbsp;</p>
          <ul>
            <li><span><strong>VERİ SORUMLUSUNUN göstereceği özen</strong></span></li>
          </ul>
          <p>(1) Kullanıcıların kişisel bilgi ve verileri, <strong><em>SVAYP</em></strong>’a ait güvenli veri tabanlarında depolanır.</p>
          <p>(2) <strong><em>SVAYP</em></strong>, kişisel bilgilerin gizliliği, güvenliği ve bütünlüğünü korumak için gerekli ve uygun korumaları sağlamaktadır. Bu konuda objektif özeni göstermemize karşın bir zararın doğması halinde, tarafımıza herhangi bir sorumluluk yüklenemez.</p>
          <ul>
            <li><span><strong>NİHAî HÜKÜM VE HUSUSLAR</strong></span></li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>Kullanıcılarımızın kişisel veri ve bilgilerinin hukuki güvenlik çerçevesinde kalarak işlenmesinin sağlanması için gerekli adımları atmakla beraber, konuyla ilgili tüm hukuksal yükümlülüklerimize uygun hareket ettiğimizi belirtiriz. 6698 sayılı KVKK ve GVKT başta olmak üzere, konu ile ilgili hukuki normlardan doğan yükümlülüklerimize uygun olarak kullanıcı verilerinin işlendiğini ve koruma altında tutulduğunu; kimlik bilgilerini içeren verilerin, <strong>hukuka aykırı</strong> olarak herhangi bir üçüncü kişi ya da kurum ile paylaşılmayacağını önemle vurgularız. Ancak veri sorumlusunun sorumluluğu, KVKK’nin GVKT’ye uygun düştüğü hükümlerle sınırlıdır.</li>
          </ul>
          <ul>
            <li>Veri sahiplerinin, işbu aydınlatma metninde ya da bilahare akdedilecek açık rıza akdinde (kullanıcı sözleşmesi) hususlarla ilgili <strong>şikayetlerini öncelikle veri sorumlusuna iletmesi ve ardından Kişisel Verilerin Korunması Kurumuna başvurması zorunludur</strong>. Veri sorumlusuna başvurulmadan Kuruma başvurulması mümkün değildir. Şikayet ve sair her türlü ileti için, tarkan@svayp.com uzantılı adresimize e-posta gönderilmesi yeterlidir.</li>
          </ul>
        </ContentStyle>
  )
}

export default Turkish