import { useEffect, useState } from "react";

export function useWidth() {
  const [width, setWidth] = useState(undefined) as any;

  function handleResize() {
    // Set window width to state
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    // only execute all the code below in client side
    if (typeof window !== "undefined") {
      // Handler to call on window resize

      // Add event listener
      window.addEventListener("resize", handleResize);

      // Call handler right away so state gets updated with initial window size
      handleResize();

      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []); // Empty array ensures that effect is only run on mount
  return width;
}
