import { FormikProps, useFormik } from "formik";
import Button from "../../../../components/button";
import LabelwithInput from "../../../../components/input";
import SelectBox from "../../../../components/selectBox";
import { Size, useWindowSize } from "../../../../hooks/useWindowSize";
import { MyOptionType, options } from "../../../../mock/select-options-data";
import { setAccountCardInfo } from "../../../../redux/features/paymentSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {
  BottomLine,
  BottomLineWrapper,
  Container,
  Form,
  FormContainer,
  FormSubmitContainer,
  FormSubmitParagraph,
  FormTitle,
  Header,
  HeaderContainer,
} from "./style";

export interface formValues {
  adSoyad: string;
  email: string;
  phone: string;
  phoneCode: string;
  country: string;
}

const AccountCard = ({
  setOpen,
  setOtherCard,
}: {
  setOpen: any;
  setOtherCard: any;
}) => {
  const dispatch = useAppDispatch();
  const { subscribePacket } = useAppSelector((state) => state.payment);
  const size: Size = useWindowSize();
  const width = size.width !== undefined && size.width >= 425 ? "48%" : "100%";
  const buttonWidth =
    size.width !== undefined && size.width >= 425 ? "30%" : "100%";
  const formik: FormikProps<formValues> = useFormik<formValues>({
    initialValues: {
      adSoyad: "",
      email: "",
      phone: "",
      phoneCode: "+90",
      country: "",
    },
    onSubmit: (values) => {
      dispatch(setAccountCardInfo(values));
      setOtherCard(true);
      setOpen(false);
    },
    validate: (values) => {
      let errors: formValues = {
        adSoyad: "",
        email: "",
        phone: "",
        phoneCode: "",
        country: "",
      };
      if (!values.adSoyad) {
        errors.adSoyad = "Required!";
      }
      if (!values.email) {
        errors.email = "Required!";
      } else if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
          values.email
        )
      ) {
        errors.email = "Invalid email format!";
      }
      if (!values.phone) {
        errors.phone = "Required!";
      } else if (
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(values.email)
      ) {
        errors.email = "Phone is number";
      }
      if (!values.country) {
        errors.country = "Required!";
      }
      return errors;
    },
  });
  return (
    <Container>
      <HeaderContainer borderColor={subscribePacket.color}>
        <Header>{subscribePacket.label}</Header>
      </HeaderContainer>
      <FormContainer>
        <FormTitle>Hesap detayları</FormTitle>
        <form onSubmit={formik.handleSubmit}>
          <Form>
            <LabelwithInput
              value={formik.values.adSoyad}
              handleChange={formik.handleChange}
              name="adSoyad"
              placeholder="Ad ve Soyad"
              style={{
                width: width,
                marginBottom: 32,
                borderColor: "red",
              }}
              errors={formik.errors.adSoyad ? true : false}
            />
            <LabelwithInput
              value={formik.values.email}
              handleChange={formik.handleChange}
              type="email"
              name="email"
              placeholder="E-posta"
              style={{
                width: width,
                marginBottom: 32,
              }}
              errors={formik.errors.email ? true : false}
            />
            <LabelwithInput
              value={formik.values.phone}
              handleChange={formik.handleChange}
              type="number"
              name="phone"
              placeholder="Telefon Numarası"
              style={{
                width: width,
                marginBottom: 32,
              }}
              selectValue={formik.values.phoneCode}
              selectHandleChange={formik.handleChange}
              errors={formik.errors.phone ? true : false}
            />
            <SelectBox
              placeholder="Ülke"
              value={formik.values.country}
              handleChange={(value: MyOptionType) =>
                formik.setFieldValue("country", value.value)
              }
              errors={formik.errors.country ? true : false}
              options={options}
              style={{
                width: width,
                marginBottom: 32,
              }}
            />
          </Form>
          <FormSubmitContainer>
            <Button
              type="submit"
              bgColor="#000"
              style={{
                color: "white",
                maxWidth: buttonWidth,
              }}
              onClick={() => {
                dispatch(setAccountCardInfo(formik.values));
                setOtherCard(true);
                setOpen(false);
              }}
            >
              Devam Et
            </Button>
            <FormSubmitParagraph>
              Devam’a tıklayarak Kullanım Koşullarını ve Gizlilik Politikasını
              kabul etmiş olursunuz. Ayrıca 16 yaşından büyük olduğunuzu kabul
              ediyorsunuz.
            </FormSubmitParagraph>
          </FormSubmitContainer>
        </form>
      </FormContainer>
      <BottomLineWrapper>
        <BottomLine color={subscribePacket.color} />
      </BottomLineWrapper>
    </Container>
  );
};

export default AccountCard;
