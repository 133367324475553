import styled from "styled-components";

export const Wrapper = styled.div<{
  isShow: boolean;
}>`
  display: ${(props) => (props.isShow ? "flex" : "none")};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  border-bottom: 2px solid black;
`;

export const NavWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 60%;
  max-width: fit-content;
  @media screen and (max-width: 425px) {
    width: 100%;
    max-width: 100%;
  }
`;
export const MobileHeader = styled.div`
  display: none;
  @media screen and (max-width: 425px) {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
  }

  /* border: 1px solid red; */
`;

export const CountriesWrapper = styled.div<{
  isOpen?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 3rem;
  border-bottom: 1px solid black;
  overflow-x: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 425px) {
    display: ${(props) => (props.isOpen ? "flex" : "none")};
    flex-direction: column;
    z-index: 100;
    height: 100vh;
    width: 65.5%;
    left: 0;
    position: fixed;
    background: #f3f3f3;
    padding: 16px;
    align-items: flex-start;
  }

  /* border: 1px solid green; */
`;
export const LeftMenuHeader = styled.div<{
  position: "right" | "left";
}>`
  display: none;
  @media screen and (max-width: 425px) {
    display: flex;
    width: 100%;
    flex-direction: ${(props) =>
      props.position === "left" ? "row" : "row-reverse"};
    justify-content: space-between;
    align-items: center;
    margin-bottom: 46px;
  }
`;

export const LeftMenu = styled.div`
  display: none;
  @media screen and (max-width: 425px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
  }
`;
export const RightHamburgerMenu = styled.div<{
  isOpen?: boolean;
}>`
  display: none;
  @media screen and (max-width: 425px) {
    display: ${(props) => (props.isOpen ? "flex" : "none")};
    flex-direction: column;
    z-index: 100;
    height: 100%;
    width: 65.5%;
    position: fixed;
    right: 0;
    background: #f3f3f3;
    padding: 12px 16px 16px 16px;
    align-items: flex-start;
    cursor: default;
    justify-content: space-between;

    img {
      cursor: pointer;
    }
  }
`;

export const CountryItem = styled.div<{
  selected: boolean;
  cursorNotAllowed: boolean;
}>`
  font-size: 11px;
  color: ${(props) => (props.selected ? "#5A5A5A" : "#C2C2C2")};
  cursor: ${(props) => (props.cursorNotAllowed ? "not-allowed" : "pointer")};
  padding: 0 0.5rem;
  margin-right: 2rem;
  white-space: nowrap;
  font-weight: ${(props) => (props.selected ? "600" : "400")};

  @media screen and (max-width: 425px) {
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 0.8rem;
  }
`;

export const CategoriesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 3rem;
  overflow-x: scroll;
  cursor: default;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ScrollToPrevCategories = styled.div`
  position: absolute;
  left: 12;
  width: 75px;
  display: flex;
  align-items: center;
  justify-content: start;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    -90deg,
    rgba(0, 0, 0, 0) 0%,
    rgb(255 255 255) 72%
  );
  z-index: 99;
  cursor: pointer;
  img {
    transform: rotate(180deg);
  }
  @media screen and (max-width: 425px) {
    display: none;
  }
`;
export const ScrollToNextCategories = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: end;
  right: 0;
  width: 75px;
  background: rgb(0, 0, 0);
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgb(255 255 255) 72%);
  z-index: 99;
  cursor: pointer;
  @media screen and (max-width: 425px) {
    display: none;
  }
`;

export const CategoryItem = styled.div<{
  selected: boolean;
}>`
  font-size: 11px;
  border-bottom: ${(props) => props.selected && "2px solid #34C759"};
  padding: 0 0.5rem;
  margin: 0 1rem;
  white-space: nowrap;
  cursor: ${(props) => (props.selected ? "default" : "pointer")};
  position: relative;
`;

export const SubscribeButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: 9.5rem;
  border: 2px solid black;
  border-radius: 0.5rem;
  position: absolute;
  top: 1.5rem;
  right: -11.5rem;
  align-self: center;
  transition: all 250ms ease;

  &:hover {
    background: black;
    color: white;
  }

  @media screen and (max-width: 425px) {
    display: none;
  }
`;

export const RightMenuSubscribeButton = styled.button`
  @media screen and (max-width: 425px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 3rem;
    width: 9.5rem;
    border: 2px solid black;
    border-radius: 0.5rem;
    align-self: center;
  }
`;

export const RightMenuTop = styled.div`
  width: 100%;
`;

export const RightMenuBottom = styled.div`
  p {
    font-size: 12px;
    color: #5a5a5a;
    margin-bottom: 16px;
  }
`;

export const RightImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const RightMenuLinks = styled.div`
  margin-top: 38px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 500;
  gap: 16px;

  a {
    text-transform: uppercase;
  }
`;

//  position: absolute;
//     top: 5%;
//     left: 2rem;
//     display: flex;
//     flex-direction: column;
//     justify-content: space-around;
//     width: 2rem;
//     height: 2rem;
//     background: transparent;
//     border: none;
//     cursor: pointer;
//     padding: 0;
//     z-index: 10;

//     &:focus {
//       outline: none;
//     }

//     div {
//       width: 2rem;
//       height: 0.25rem;
//       background: red;
//       border-radius: 10px;
//       transition: all 0.3s linear;
//       position: relative;
//       transform-origin: 1px;
//     }

export const MenuLazyLoeader = styled.div`
  width: 100%;
  height: 20px;
  margin-left: 18px;
  background: #c6c6c6;
  -webkit-animation: LazyLoadAnimation 0.7s ease infinite reverse;
  -moz-animation: LazyLoadAnimation 0.7s ease infinite reverse;
  animation: LazyLoadAnimation 0.7s ease infinite reverse;
  opacity: 0.5;
  background: linear-gradient(
    135deg,
    rgba(211, 223, 232, 0.5) 30%,
    rgba(169, 184, 197, 0.5) 38%,
    rgba(168, 182, 193, 0.6) 41%,
    rgba(168, 182, 193, 0.6) 50%,
    rgba(168, 182, 193, 0.6) 50%,
    rgba(169, 184, 197, 0.6) 51%,
    rgba(211, 223, 232, 0.6) 80%,
    rgba(211, 223, 232, 0.6) 80%
  );
  background-size: 1200% 1200%;

  @-webkit-keyframes LazyLoadAnimation {
    0% {
      background-position: 0% 92%;
    }
    50% {
      background-position: 100% 9%;
    }
    100% {
      background-position: 0% 92%;
    }
  }
  @-moz-keyframes LazyLoadAnimation {
    0% {
      background-position: 0% 92%;
    }
    50% {
      background-position: 100% 9%;
    }
    100% {
      background-position: 0% 92%;
    }
  }
  @keyframes LazyLoadAnimation {
    0% {
      background-position: 0% 92%;
    }
    100% {
      background-position: 100% 9%;
    }
    /*     100%{background-position:0% 92%} */
  }
`;
