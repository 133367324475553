/* eslint-disable jsx-a11y/no-distracting-elements */
import { useEffect } from "react";
import { setSkip } from "../../redux/features/cardsSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";

export const ScrollCards = () => {
  const dispatch = useAppDispatch();
  const { cards } = useAppSelector((state) => state.cards);

  useEffect(() => {
    handleContinue();
  }, []);

  function handleStop() {
    //@ts-ignore
    document.getElementById("marquee").stop();
  }

  function handleContinue() {
    //@ts-ignore
    document.getElementById("marquee").start();
  }

  function handleCard(index: number) {
    dispatch(setSkip(index));
  }
  return (
    //@ts-ignore
    <marquee
      id="marquee"
      loop="infinite"
      onMouseOver={() => handleStop()}
      onMouseOut={() => handleContinue()}
    >
      <ul>
        {cards?.map((card, index: number) => (
          <li key={index} onClick={() => handleCard(index)}>
            {card.title}
          </li>
        ))}
      </ul>
      {/* @ts-ignore */}
    </marquee>
  );
};
