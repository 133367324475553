import { ContentStyle } from './style';

const English = () => {
  return (
    <ContentStyle>
          <p>&nbsp;</p>
          <p>The application, which exists in accordance with the current legislation under the name of <strong><em>SVAYP</em></strong> and will be referred to as “<strong><em>SVAYP</em></strong>” throughout this text, shows ultimate attention to the Law No. 6698 on the Protection of Personal Data (“KVKK”) and the rights and obligations required by all relevant legal regulations. And this text is prepared in accordance with the legal requirements and has been devoted to enlighten it’s users.</p>
          <p>The activities of <strong><em>SVAYP</em></strong> on the internet are mainly carried out on the website (www.<strong><em>SVAYP</em></strong>.com) where this privacy policy is shown; all acts and transactions are carried out in accordance with the relevant legislation, including the Law No. 5651.</p>
          <p>The application, whose name and information is given above, processes data in accordance with to the Law No. 6698 on the Protection of Personal Data (<em>hereinafter referred to as “KVKK</em>”). The information of the representative to be reached in terms of the processed data is as follows:</p>
          <p><span><strong>Full Name:</strong></span> Tarkan KAYA / <strong><em>SVAYP</em></strong></p>
          <p><span><strong>E Mail <span className="Apple-converted-space">&nbsp; &nbsp; &nbsp; </span>:</strong></span> tarkan@<strong>svayp</strong>.com / tarkan.kaya@gmail.com</p>
          <p>The basic data processing conditions in this clarification text and the explicit consent text (user agreement) which is in a form extension of this text which will be concluded later, is based on the law KVKK 6698 and GDPR (General Data Protection Regulation) which is published in the L / 119/1 issue of the Official Journal of the European Union. However, the responsibility of the data controller is limited to the provisions that KVKK is in compliance with GDPR.</p>
          <p>In this clarification text, in accordance with the conditions required by law;</p>
          <ul>
            <li><strong><em>Contact information of the data controller,</em></strong></li>
            <li><strong><em>Basic legal / contractual responsibilities of the data controller,</em></strong></li>
            <li><strong><em>The purpose of processing the data, to whom and with which motives can be transferred</em></strong></li>
          </ul>
          <ul>
            <li><strong><em>The ways of processing the data,</em></strong></li>
            <li><strong><em>The ways of obtaining data and legal reasons for data collection</em></strong></li>
          </ul>
          <p>is listed.</p>
          <p>This clarification text needs to be read and understood by personal data owners. The text is shown in the main page of <strong><em>SVAYP</em></strong> with meanwhile users are presented with a link that will direct them to the full text. Existance of this link means the privacy policy is provided to users. <span><strong>The data controller fulfills the obligatory duty of clarification by keeping this text ready to be read and presenting it to the data owners in accordance with the law KVKK no 6698 Article 10.</strong></span></p>
          <p>Data processing of the data controller becomes lawful and valid after the following stages:</p>
          <ul>
            <li>After the clarification text is read by the data holder when the data holders are informed,</li>
            <li>Upon the <strong>approval of the EXPLICIT CONSENT TEXT</strong> which acts as an addition to this clarification text where the explicit consent for data process is given by the data holder. (<em>Except for the cases where explicit consent is not required according to the Law 6698 on Protection of Private data</em>)</li>
          </ul>
          <p>&nbsp;</p>
          <p><strong>REMINDER THAT THE CASES OF DATA PROCESSING WHERE OPEN CONSENT</strong> <strong>ISN’T REQUIRED </strong>in the presence of situations specified in the law without the need for the steps above are listed in the KVKK No 6698 Article 5:</p>
          <ul>
            <li><em>it is clearly provided for by the laws. </em></li>
            <li><em>it is mandatory for the protection of life or physical integrity of the person or of any other person who is bodily incapable of giving his consent or whose consent is not deemed legally valid. </em></li>
            <li><em>processing of personal data belonging to the parties of a contract, is necessary provided that it is directly related to the conclusion or fulfilment of that contract. </em></li>
            <li><em>it is mandatory for the controller to be able to perform his legal obligations. </em></li>
            <li><em>the data concerned is made available to the public by the data subject himself. </em></li>
            <li><em>data processing is mandatory for the establishment, exercise or protection of any right. </em></li>
            <li><em>it is mandatory for the legitimate interests of the controller, provided that this processing shall not violate the fundamental rights and freedoms of the data subject.</em></li>
          </ul>
          <p>The cases where the data processing without explicit consent is also listed in GDPR article 6 as follows:</p>
          <ul>
            <li><em>processing is necessary for the performance of a contract to which the data subject is party or in order to take steps at the request of the data subject prior to entering into a contract;</em></li>
            <li><em>processing is necessary for compliance with a legal obligation to which the controller is subject;</em></li>
            <li><em>processing is necessary in order to protect the vital interests of the data subject or of another natural person;</em></li>
            <li><em>processing is necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller;</em></li>
            <li><em>processing is necessary for the purposes of the legitimate interests pursued by the controller or by a third party, except where such interests are overridden by the interests or fundamental rights and freedoms of the data subject which require protection of personal data, in particular where the data subject is a child.</em></li>
          </ul>
          <p>In these both cases where the explicit consent is not required according to KVKK (6698) or GDPR, THE DATA PROCESS CAN BE CARRIED OUT WITHOUT THE CONSENT OF THE DATA OWNER.</p>
          <p>The consent mentioned above stands for “consent given with free will after being informed”. The details related to this are both stated in this text and USER AGREEMENT (EXPLICIT CONSENT TEXT)<span className="Apple-converted-space">&nbsp; </span>that will be agreed upon afterwards.</p>
          <p><strong>Before parties consent to this text and agreement we should state that: </strong></p>
          <p>Data processing stands for; saving, changing data, obtaining, transferring, rearranging, explaining<span>, s</span>haring with third parties, data storage. <strong><em>SVAYP</em></strong> is responsible for the execution of the said works and transactions (data processing).<span> Data processes to be allowed and the way the data is going to be processed is stated bellow.</span></p>
          <p>&nbsp;</p>
          <p><strong>E X P L A N A T I O N S</strong></p>
          <p>&nbsp;</p>
          <ul>
            <li><span><strong>DATA PROCESSING POLICY</strong></span></li>
          </ul>
          <ul>
            <li>Responsibility of complying with legal obligations regarding the processing of personal data of the user is on the data holder in condition of acting in accordance with all applicable legal regulations, especially KVKK numbered 6698.</li>
          </ul>
          <ul>
            <li>Users' personal data are of utmost importance to us; This reflects the attention we show in gaining the trust of our users, institutions and persons who shares their personal data with us<strong>.</strong> <strong>The processing of personal data is possible only </strong><span><strong>if the conditions sought in the law or the relevant legal norms allow it</strong></span> or with you consent.<strong> In other cases your </strong><span><strong>explicit consent</strong></span><strong> is always required. </strong>However, in cases where the law states that no explicit consent is required <strong><em>SVAYP</em></strong> is able to process data without your explicit consent. The data is processed according the the purposes mentioned in this privacy policy in <span><strong>a proportionate way</strong></span>.</li>
          </ul>import Turkish from './content/Turkish';

          <p>&nbsp;</p>
          <ul>
            <li><span><strong>DATA PROCESSING METHOD AND PURPOSE</strong></span></li>
          </ul>
          <ul>
            <li><strong><em>SVAYP</em></strong> may duly process the data recorded in the application for data owners who accept the terms of this disclosure text and privacy policy and the user agreement to be concluded afterwards. Processing; Information / request / membership forms in the website, mobile application and social media are carried out electronically (as explained below), such as the recording, storage and transfer of personal data to be provided by the data owners in applications called applications. <strong><em>SVAYP </em></strong>is called “data holder” in this regard.</li>
          </ul>
          <ul>
            <li><strong><em>SVAYP </em></strong>doesn’t require membership or data sharing in order for users to use it’s website and apps. However, membership allows users to enjoy more functional service compared to it’s non-membership options. Users are asked to provide their full names, e mail addresses, phone numbers and their current residance.</li>
          </ul>
          <ul>
            <li>Personal data; can be obtained, saved, stored by <strong><em>SVAYP</em></strong> with the following the steps mentioned above; To the extent permitted by the legislation, it can be <span><strong>disclosed, transferred, passed on, shared, classified, anonymized</strong></span> <strong>and processed in other ways specified in the law to third parties at home and abroad</strong>. Meanwhile for the mandatory cookies that are required in order for the website to operate, explicit consent is not required. The users are notified in the main page about <span><strong>mandatory</strong></span> cookies and this notification is enough for <strong><em>SVAYP </em></strong>to be able to use them. But for website to serve better, performance enhancer cookies or advertisement cookies require explicit consent of the user to be processsed.</li>
          </ul>
          <ul>
            <li><strong><em>SVAYP</em></strong> will collect and use information and data in accordance with legal requirements, keep them for a certain period of time, transfer them to third parties, make an anonymised questionnaire and process them in any way written above. The main purpose of <strong><em>SVAYP</em></strong> in data processing is to carry out commercial activities and to conduct public opinion research<strong>. </strong>In other words<strong>; </strong><span><strong>Personal opinions and preferences that are shared to <em>SVAYP</em> are turned into a survey by keeping the names and surnames of the users confidential thus anonymizing them; the data will be used for execution of strategic planning activities of trading companies and other third parties</strong></span><strong>.</strong> The created surveys and stored personal data are anonymized and transferred to third parties, community companies or other persons.</li>
          </ul>
          <ul>
            <li><strong><em>SVAYP</em></strong> takes all necessary digital measures to keep the data it processes to prevent any damages.</li>
          </ul>
          <ul>
            <li>If the data owner publicises his/her personal data that they are going to upload to the system before or after he/she registers to the software or the site; said data can be processed WITHOUT REQUIRING EXPLICIT CONSENT according to the article 5 of Law No. 6698</li>
          </ul>
          <ul>
            <li>Users’ <strong>right to waive</strong> is reserved. <span><strong>The user can demand the saved and processed personal data to be deleted</strong>.</span> For this, it is necessary to contact us via <strong><em>SVAYP</em></strong> to contact our authorities. However, in cases where the right and obligation to preserve personal data in accordance with the provisions of the relevant legislation, this demand may not be fulfilled although this conditions are limited with the cases that is stated in the (page 2) stated above.</li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li><span><strong>THE WAY THE DATA IS COLLECTED AND THE TECHNICAL ISSUES RELATED TO IT</strong></span></li>
          </ul>
          <ul>
            <li>It must be reminded that the concept of <strong><em>personal data</em></strong> includes not only the names, photographs and similar concrete information of the persons, but also the computers, IP addresses, cookies and similar electronic information. The transmission of personal data and the processing of the transmitted data by us basically depend on the explicit consent and notification of the data owner. The data is notified with this text provided to him/her.</li>
          </ul>
          <ul>
            <li>As stated above, when <strong><em>SVAYP</em></strong> is used, data is collected about data owners in accordance with the legislation. <span><strong>The first data processing begins when the mandatory cookies are used and presented to the user.</strong></span> Other data processing procedures are carried out <span><strong>after the necessity to enlighten the user is fulfilled by <em>SVAYP</em></strong></span> and explicit consent is given. Data collection process basically works as follows. As an exception to this, <strong><em>SVAYP</em></strong> automatically starts using cookies in order to provide a more effective service to users, if the users do not change the cookie settings of the browsers (leave them available for processing); Consent of the user in this respect is deemed to have been obtained once user enters the website. <span><strong>Performance enhancer cookies and advertisement cookies require consent. Consent to both is given when the “agree” button is clicked in the main page of the site.</strong></span></li>
          </ul>
          <ul>
            <li>Technical issues regarding the data processing policy and processing methods regarding cookies are expressed in 3 articles:</li>
          </ul>
          <ul>
            <li><strong><em>Data Sent to SVAYP by Data Owners' Web Browsers</em></strong></li>
          </ul>
          <p>&nbsp;</p>
          <p><strong><em>SVAYP</em></strong> can collect information automatically sent to us by the web browser that logs into the website or application address. This information includes the IP address of the data subject's internet service provider, the name and type of the operating system, the version of the web browser and other technical issues.</p>
          <p>&nbsp;</p>
          <p>Regarding this issue, what kind of information the browser sends about the user is a matter entirely under the responsibility and authority of the DATA OWNER. In case the users want to stop the flow of browser information about the subject, the relevant measures can be taken through the browsers and by themselves. <strong><em>SVAYP</em></strong> does not accept any responsibility regarding the matter.</p>
          <p>&nbsp;</p>
          <ul>
            <li><strong><em> Personal Data Obtained through "Cookies" on the Electronic Device and Browsers of the Data Owner</em></strong></li>
          </ul>
          <p>&nbsp;</p>
          <p><strong><em>SVAYP</em></strong> can collect information by installing a "tag" on the hard drive of the user's computer. This tag is called "cookie" technologically. <strong><em>SVAYP</em></strong> can use the said cookies and similar technical data for it’s professional and commercial purposes. The aforementioned situation depends on fulfillment of the conditions mentioned above.</p>
          <p>&nbsp;</p>
          <table className="t1" cellSpacing={0} cellPadding={0}>
            <tbody>
              <tr style={{minWidth: '140px'}}>
                <td style={{textAlign: 'center', minWidth: '140px'}} valign="middle">
                  <p>&nbsp;</p>
                  <p><strong>Mandatory Cookies</strong></p>
                </td>
                <td style={{minWidth: '140px'}} valign="middle">
                  <p>Mandatory cookies are cookies that absolutely necessary for the website to operate and work as it is intended to be used. If the mandatory cookies are blocked, some parts of the website may not work properly. Mandatory cookies allow the website to enhance user security, save search history, to sign the user in and has similar functions and cannot be used for marketing purposes. Does not require explicit consent to operate.</p>
                </td>
              </tr>
              <tr style={{minWidth: '140px'}}>
                <td style={{textAlign: 'center', minWidth: '140px'}} valign="middle">
                  <p><strong>Functional Cookies</strong></p>
                </td>
                <td style={{minWidth: '140px'}} valign="middle">
                  <p>Functional cookies help <strong>personalize </strong>the website content based on the preferences of the visitors. Requires explicit consent.</p>
                </td>
              </tr>
              <tr style={{minWidth: '140px'}}>
                <td style={{textAlign: 'center', minWidth: '140px'}} valign="middle">
                  <p><strong>Performance Cookies</strong></p>
                </td>
                <td style={{minWidth: '140px'}} valign="middle">
                  <p>Performance cookies help the website to be developed. Performance cookies collect information such as how and for how long the website is used. Requires explicit consent.</p>
                </td>
              </tr>
              <tr style={{minWidth: '140px'}}>
                <td style={{textAlign: 'center', minWidth: '140px'}} valign="middle">
                  <p><strong>Advertisement Cookies</strong></p>
                </td>
                <td style={{minWidth: '140px'}} valign="middle">
                  <p>Advertisement cookies are used to provide the users with contents and advertisements that are targeted to user. This cookies match the other data that is obtained through cookies with the data it has collected itself to provide the target user with special dicounts and products. Advertisement cookies do not provide the user with a product or a discount again if the user has stated that they do not wish to see that content before. Requires explicit consent.</p>
                </td>
              </tr>
            </tbody>
          </table>
          <p>&nbsp;</p>
          <p>The MANDATORY COOKIES as it is mentioned above DO NOT REQUIRE EXPLICIT CONSENT TO OPERATE. Other cookies on the other hand all require explicit consent of the user to be operated. The users are notified about mandatory cookies on the main page when they enter the website. If the user clicks “agree” button, they consent to other cookies to be operated by the website that are mentioned above. These cookies (non mandatory cookies) start to operate after the said consent is given.</p>
          <p>&nbsp;</p>
          <ul>
            <li><strong><em>Personal Data that is Provided by Data Owners Knowingly and Willingly</em></strong></li>
          </ul>
          <p>&nbsp;</p>
          <p><strong><em>SVAYP</em></strong> collects the personal data and information described the data and information provided knowingly and willingly with the other kinds of data mentioned above. Examples include data and information that data subjects provided when they sign up to receive email notifications, when they fill out a form, or ask us a question for feedback, or the data they provide to us in an email they sent.</p>
          <p>&nbsp;</p>
          <p>By reading OR accepting this privacy policy and the user agreement to be concluded subsequently, it is acknowledged that <strong><em>SVAYP</em></strong> is granted use the data for the above-mentioned data processing and commercial purposes.</p>
          <p>&nbsp;</p>
          <ul>
            <li><span><strong>LEGAL RIGHTS OF DATA OWNERS (USERS)</strong></span></li>
          </ul>
          <ul>
            <li><strong><em>SVAYP</em></strong> collects the data that is provided knowingly and willingly by it’s users with the personal data and info mentioned above. Examples of this data might be when the data owners register to be informed with e mail notifications, when they fill out a form or when they ask a question or send an e mail.</li>
          </ul>
          <ul>
            <li>With this privacy policy and user agreement that will be concluded later, the user agrees that their data may be used according to the purposes that are mentioned in this text and that <strong><em>SVAYP</em></strong> may use this data for it’s commercial purposes.</li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li><span><strong>LEGAL RIGHTS OF DATA OWNERS (USERS)</strong></span></li>
          </ul>
          <p>&nbsp;</p>
          <p><strong><em>Data owners have right to</em></strong>;</p>
          <p>• Learn whether personal data is processed,</p>
          <p>• Request information if it has been processed,</p>
          <p>• Learn the purpose of data processing and whether they are used appropriately,</p>
          <p>• Learn the people to whom data is transferred,</p>
          <p>• Request correction of data in case of incomplete or incorrect processing,</p>
          <p>• Request the deletion or elimination of personal data when the conditions are met, (<em>Withdrawal of consent for data processing will have a prospective effect, and the processes carried out until the moment of withdrawal will continue to be in compliance with the law</em>.)</p>
          <p>• Request the correction, deletion or destruction operations be communicated to third parties to whom the data was transferred,</p>
          <p>• Object to the occurrence of a result against themselves by analyzing the processed data exclusively through automated systems,</p>
          <p>&nbsp;</p>
          <ul>
            <li><span><strong>RESPONSIBILITY OF THE DATA HOLDER</strong></span></li>
          </ul>
          <ul>
            <li>Personal information and data of users are stored in secure databases of <strong><em>SVAYP</em></strong>.</li>
          </ul>
          <ul>
            <li><strong><em>SVAYP</em></strong> provides necessary and appropriate protections to protect the confidentiality, security and integrity of personal information. In cases where a damage occurs regardless of our objective attention, <strong><em>SVAYP</em></strong> cannot be held liable.</li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li><span><strong>FINAL PROVISIONS AND MATTERS</strong></span></li>
          </ul>
          <ul>
            <li>While we take the necessary steps to ensure that the personal data and information of our users are processed within the framework of legal security, we state that we act in accordance with all our legal obligations on the subject. User data are processed and kept under protection in accordance with our obligations arising from the relevant legal norms, spesifically KVKK and GDPR; We emphasize that data containing identification information will not be shared with any third person or institution <strong>illegally</strong>. However, the liability of the data controller is limited to the provisions that KVKK complies with GDPR.</li>
          </ul>
          <ul>
            <li>Users that have <strong>complaints about the matters in this privacy policy or matters in the explicit consent agreement</strong> (user agreement) to be concluded later <strong>are obliged to contact the data holder first and then apply to Protection of Private Data Office. </strong>It is not possible to apply to the Protection of Privade Data Office before contacting the data holder. For complaints and for similar contacts, users may send an e mail to our adress tarkan@svayp.com</li>
          </ul>
        </ContentStyle>
  )
}

export default English