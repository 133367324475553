import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { countries } from "../../mock/data";
import { resetSkip, setCountryId } from "../../redux/features/cardsSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  Wrapper,
  NavWrapper,
  CountriesWrapper,
  CountryItem,
  CategoriesWrapper,
  CategoryItem,
  SubscribeButton,
  LeftMenuHeader,
  LeftMenu,
  RightHamburgerMenu,
  RightMenuSubscribeButton,
  MobileHeader,
  RightMenuLinks,
  RightMenuTop,
  RightMenuBottom,
  RightImageWrapper,
  ScrollToNextCategories,
  ScrollToPrevCategories,
  MenuLazyLoeader,
} from "./styles";
import CategoryIcon from "../../assets/navbar/categorySvg.svg";
import Down from "../../assets/navbar/Down.svg";
import Up from "../../assets/navbar/Up.svg";
import SvaypLogo from "../../assets/footer/svaypLogo.svg";
import RightMenu from "../../assets/navbar/HamburgerMenu.svg";
import RightArrow from "../../assets/navbar/right-arrow.svg";
import Close from "../../assets/navbar/Close.svg";
import { Link, useNavigate } from "react-router-dom";
import { setShowHeader } from "../../redux/features/subscribeSlice";
import { getCards } from "../../redux/api/cardServices";
import { HttpRequest, RequestType } from "../../plugins/httpRequest";
import { Category } from "../../types/category";
import AppstoreLogo from "../../assets/footer/appstoreLogo.svg";
import GoogleplayLogo from "../../assets/footer/googleplayLogo.svg";

const Navbar = () => {
  let navigate = useNavigate();
  const { t, i18n} = useTranslation(['navbar']);

  const dispatch = useAppDispatch();
  const { isShowHeader } = useAppSelector((state) => state.subscribe);
  const { skip } = useAppSelector((state) => state.cards);

  const [categories, setCategories] = useState<Category[]>([]);
  const [categoryAll, setCategoryAll] = useState<any>( () => {
    switch (localStorage.getItem("language")) {
      case "tr":
        return { name: "Tümü" };
      default:
        return { name: "All" };
    }
  });
  const [selectedCategory, setSelectedCategory] = useState<any>( () => {
    switch (localStorage.getItem("language")) {
      case "tr":
        return { name: "Tümü" };
      default:
        return { name: "All" };
    }
  });
  const userLanguage = navigator.language.split('-')[0];
  const languageToLoad:any = localStorage.getItem('language') ? localStorage.getItem('language') :  userLanguage === 'tr' ? 'tr' : 'us';
  const findLanguage:any = countries.find(x => x.code === languageToLoad);
  const [selectedCountry, setSelectedCountry] = useState(findLanguage || countries[0]);
  const [leftMenuOpen, setLeftMenuOpen] = useState(false);
  const [rightMenuOpen, setRightMenuOpen] = useState(false);

  const [menuLazyLoader, setMenuLazyLoader] = useState(false);

  useEffect(() => {
    setMenuLazyLoader(true);

    const intervalId = setInterval(() => {
      setMenuLazyLoader(false);
    }, 500);

    return () => {
      clearInterval(intervalId);
    };
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, countries, localStorage.getItem('language')]);





  useEffect(() => {
    if (!localStorage.getItem('language')) {
      localStorage.setItem('language', languageToLoad);
    }else{
      i18n.changeLanguage(languageToLoad);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageToLoad]);
      


  const selectCountry = (country: any) => {
    if (country.id === "") return;
    setSelectedCountry(country);
    dispatch(resetSkip());
    dispatch(setCountryId(country.id));
    dispatch(getCards({ skip: 0, countryId: country.id }));
  };


  useEffect(() => {
    HttpRequest<null, Category[]>({
      method: RequestType.GET,
      url: `Client/Categories/All?countryId=${selectedCountry.id}`,
    }).then((response) => {
      setCategories(response);
    });
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedCategory?.id) {
      dispatch(
        getCards({
          skip: skip ? skip : 0,
          countryId: selectedCountry.id,
          categoryId: selectedCategory?.id,
        })
      );
    } else {
      dispatch(
        getCards({ skip: skip ? skip : 0, countryId: selectedCountry.id })
      );
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, skip]);


  // Scroll to next categories

  const scrl = React.useRef<HTMLDivElement>(null);

  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);

  const slide = (shift: number) => {
    if (scrl.current) {
      scrl.current.scrollLeft += shift;
      setscrollX(scrollX + shift);
      if (
        Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
        scrl.current.offsetWidth
      ) {
        setscrolEnd(true);
      } else {
        setscrolEnd(false);
      }
    }
  };

  useEffect(() => {
    if (
      scrl.current &&
      scrl?.current?.scrollWidth === scrl?.current?.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
    return () => {};
  }, [scrl?.current?.scrollWidth, scrl?.current?.offsetWidth]);

  return (
    <Wrapper isShow={isShowHeader}>
      <NavWrapper>
        {/*  Mobile View Header */}
        <MobileHeader>
          <LeftMenu onClick={() => {
            setLeftMenuOpen(!leftMenuOpen)
            setRightMenuOpen(false)
          }}>
            <p
              style={{
                margin: 0,
                padding: 0,
                fontSize: 11,
                lineHeight: "22px",
                fontWeight: "400",
              }}
            >
              {selectedCountry.name}
            </p>
            <img src={Down} alt="DownIcon" height={16} width={16} />
          </LeftMenu>
          <img src={SvaypLogo} alt="Svayp" height={24} width={96} />
          <div
            style={{ width: 62, display: "flex", justifyContent: "flex-end" }}
          >
            <img
              src={RightMenu}
              alt="right"
              height={24}
              width={24}
              onClick={() => {
                setRightMenuOpen(!rightMenuOpen)
                setLeftMenuOpen(false)
              }}
            />
          </div>
        </MobileHeader>
        {/*  Mobile View Right Hamburger Menu */}
        <RightHamburgerMenu isOpen={rightMenuOpen}>
          <RightMenuTop>
            <LeftMenuHeader position="right">
              <img
                src={Close}
                alt="Close"
                height={32}
                width={32}
                onClick={() => setRightMenuOpen(false)}
              />
              <img src={SvaypLogo} alt="Svayp" height={24} width={96} />
            </LeftMenuHeader>
            <RightMenuSubscribeButton
              onClick={() => {
                navigate("subscribe");
                dispatch(setShowHeader(false));
              }}
            >
              {t('subscribe')}
            </RightMenuSubscribeButton>
            <RightMenuLinks>
              <Link to="/privacy-policy">{t('privacy-policy')}</Link>
              <Link to="/user-agreement">{t('user-agreement')}</Link>
            </RightMenuLinks>
          </RightMenuTop>
          <RightMenuBottom>
            <p>{t('download-app')}</p>
            <RightImageWrapper>
              <img src={AppstoreLogo} alt="appstore-logo" width={140} />
              <img src={GoogleplayLogo} alt="playstore-logo" width={140} />
            </RightImageWrapper>
          </RightMenuBottom>
        </RightHamburgerMenu>
        {/*  Mobile View Left Hamburger Menu  */}
        <CountriesWrapper isOpen={leftMenuOpen}>
          <LeftMenuHeader position="left">
            <img
              src={Up}
              alt="UpIcon"
              onClick={() => setLeftMenuOpen(!leftMenuOpen)}
            />
            <img src={SvaypLogo} alt="Svayp" height={24} width={96} />
          </LeftMenuHeader>
          {countries.map((country: any, index) => (
            <CountryItem
              key={index}
              selected={selectedCountry.id === country.id}
              cursorNotAllowed={country.id === ""}
              onClick={() => {
                localStorage.setItem("language", country.code);
                if (country.id !== "") {
                  i18n.changeLanguage(country.code)
                  selectCountry(country);
                  setLeftMenuOpen(!leftMenuOpen);
                  setSelectedCategory(() => {
                    switch (localStorage.getItem("language")) {
                      case "tr":
                        return { name: "Tümü" };
                      default:
                        return { name: "All" };
                    }
                  });
                  setCategoryAll(() => {
                    switch (localStorage.getItem("language")) {
                      case "tr":
                        return setCategoryAll({ name: "Tümü" });
                      default:
                        return setCategoryAll({ name: "All" });
                    }
                  });
                }
              }}
            >
              {country.name}
            </CountryItem>
          ))}
        </CountriesWrapper>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: "3rem",
            alignItems: "center",
            paddingLeft: "1rem",
          }}
        >
          <img src={CategoryIcon} alt="send-icon" width={24} height={24} />
          {menuLazyLoader && <MenuLazyLoeader />}
         {!menuLazyLoader && <CategoriesWrapper ref={scrl}>
            {scrollX !== 0 && (
              <ScrollToPrevCategories onClick={() => slide(-100)}>
                <img src={RightArrow} alt="Prev" />
              </ScrollToPrevCategories>
            )}
            {!scrolEnd && (
              <ScrollToNextCategories onClick={() => slide(+100)}>
                <img src={RightArrow} alt="Next" />
              </ScrollToNextCategories>
            )}
            <CategoryItem
              selected={selectedCategory?.name === categoryAll?.name}
              onClick={() => {
                setSelectedCategory(categoryAll);
                dispatch(resetSkip());
                dispatch(getCards({ skip: 0, countryId: selectedCountry.id }));
              }}
            >
              {categoryAll?.name}
            </CategoryItem>
            {categories.map(
              (category: any) =>
                category.status === "ACTIVE" &&
                category.totalCount !== 0 && (
                  <CategoryItem
                    key={category.id}
                    selected={selectedCategory === category}
                    onClick={() => {
                      setSelectedCategory(category);
                      dispatch(resetSkip());
                      dispatch(
                        getCards({
                          skip: 0,
                          countryId: selectedCountry.id,
                          categoryId: category.id,
                        })
                      );
                    }}
                  >
                    {category.title}
                  </CategoryItem>
                )
            )}
          </CategoriesWrapper>}
        </div>

        <SubscribeButton
          onClick={() => {
            navigate("subscribe");
            dispatch(setShowHeader(false));
          }}
        >
          {t('subscribe')}
        </SubscribeButton>
      </NavWrapper>
    </Wrapper>
  );
};

export default Navbar;
