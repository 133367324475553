import React from "react";
import styled from "styled-components";
interface IButton {
  type?: "submit" | "button";
  style?: any;
  bgColor?: string;
  borderColor?: string;
  onClick?: () => void;
  disabled?: boolean;
  children: any;
}

const Button = ({
  bgColor = "transparent",
  borderColor = "transparent",
  type = "button",
  style,
  children,
  disabled,
  onClick,
}: IButton) => {

  const SButton = styled.button`
  border-radius: 12px;
  border: 2px solid ${borderColor};
  background-color: ${bgColor};
  padding: 16px;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 25px;
  transition: all 250ms ease;

  &:hover {
    background-color: ${bgColor === "transparent" && `${borderColor} !important`};
    color: white !important;
  }
  `;


  return (
    <SButton
      type={type}
      disabled={disabled}
      onClick={onClick}
      style={{
        ...style,
      }}
    >
      {children}
    </SButton>
  );
};

export default Button;
