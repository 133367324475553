import styled from "styled-components";

export const ContentStyle = styled.p`
  li {
    &:hover {
      text-decoration: none !important;
      cursor: default;
    }
  }
  th,
  td {
    border: 1px solid white;
    padding: 8px;
  }
  tr {
    display: flex;
  }
  td {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  ul {
    margin-left: 40px;
  }
`;
